<template>
    <div ref="calContainer">
        <!-- hidden input field for reading files -->
        <input ref="inputFileC" id="inputfileC" name="inputfileC" type="file" @change="this.importData" />

        <v-container fluid class="pa-0 ma-0">
            <v-dialog v-model="showEditText" persistent max-width="600px">
                <v-card>
                    <v-card-title>Edit Event</v-card-title>


                    <v-text-field v-model="textareaTitle" label="Title" outlined></v-text-field>

                    <v-card-text>
                        <v-textarea v-model="textareaText" clearable clear-icon="mdi-close-circle"
                            label="Text"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="updateEventText">Save</v-btn>
                        <v-btn @click="showEditText = !showEditText">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>


        <v-dialog v-model="showDialogModel" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ confirmTitle }}</v-card-title>
                <v-card-text v-html="confirmText"></v-card-text>
                <v-card-actions>
                    <v-btn color="red darken-1" text @click="cancelConfirm">No</v-btn>
                    <v-btn color="green darken-1" text @click="confirmEvent">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 
      <v-sheet
  color="white"
  elevation="6"
  height="100"
  outlined
  rounded
  shaped
  width="100"
></v-sheet> -->

        <v-sheet>
            <!-- NOTHING SELECTED MENU CLOSED-->

            <v-container v-if="showExtraMenu === false && currentEvent === null"
                class="d-flex align-center justify-center">
                <v-row>
                    <v-col>
                        <div class="text-center">
                            <v-label>{{ eventsInfo }}</v-label>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <!-- MENU OPEN -->
            <v-row v-if="showExtraMenu === true && currentEvent === null" class="">
                <v-col cols="6" class="pa-0 ma-0 d-flex">
                    <BaseBtn :lazyColorType="3" :onClick="Deselect" :fontAwesomeOn="'fa-solid fa-circle-xmark fa-fw'"
                        :fontAwesomeOff="'fa-solid fa-circle-xmark fa-fw'" />
                    <!-- When am event/item is selected -->
                    <BaseBtn text="Import" :onClick="importDataClick" />

                    <BaseBtn text="Export" :onClick="exportDataClick" />

                    <BaseBtn :lazyColorType="2" :fontAwesomeOn="'fa-solid fa-bomb fa-fw'"
                        :fontAwesomeOff="'fa-solid fa-bomb fa-fw'" :onClick="toggleClearAllEvents" />

                    <div class="d-flex align-center justify-center ml-2 mr-2">
                        <v-switch v-model="settings.nextMondaySwitch" :label="`Next Monday`" hide-details inset
                            flat></v-switch>
                    </div>
                </v-col>

                <v-col cols="2" class="pa-0 ma-0 d-flex align-center justify-center">
                    <v-slider class="ml-2" v-model="settings.intervalHeightValue" :label="intervalHeightValueLabel"
                        ticks="always" tick-size="4" :min="40" :max="240" :step="40" hide-details></v-slider>
                </v-col>

                <v-col cols="2" class="pa-0 ma-0 d-flex align-center justify-center">
                    <v-slider v-model="settings.startIntervalCalendar" :label="startIntervalCalendarLabel"
                        ticks="always" tick-size="4" :min="0" :max="12" :step="1" hide-details></v-slider>
                </v-col>

                <v-col cols="2" class="pa-0 ma-0 d-flex align-center justify-center">
                    <v-slider v-model="settings.endIntervalCalendar" :label="endIntervalCalendarLabel" ticks="always"
                        tick-size="4" :min="14" :max="24" :step="1" hide-details></v-slider>
                </v-col>
            </v-row>
            <!-- CURRENT EVENT SELECTED -->
            <div v-if="currentEvent !== null" class="d-flex" style="flex-grow: 1">
                <v-text-field ref="titleInputField" hide-details="auto" v-model="title"
                    @input="titleChanged"></v-text-field>

                <!-- <v-icon icon="fas fa-plus" />
          <v-icon icon="mdi:mdi-minus" /> -->
                <div class="d-flex align-center justify-center ma-2">
                    {{ currentEventIntervalTimeDisplay }}
                </div>
                <div class="myzcontainer" @click="changeColorClick($event)">
                    <span class="myzitem myzfarve1"></span><span class="myzitem myzfarve2"></span><span
                        class="myzitem myzfarve3"></span><span class="myzitem myzfarve4"></span><span
                        class="myzitem myzfarve5"></span><span class="myzitem myzfarve6"></span>
                </div>

                <BaseBtn v-if="lastEvent !== null && isDragging === false" :fontAwesomeOn="'fa-solid fa-clone fa-fw'"
                    :fontAwesomeOff="'fa-solid fa-clone fa-fw'" :onClick="cloneEvent" />
                <BaseBtn :fontAwesomeOn="'fa-solid fa-pen-to-square fa-fw'"
                    :fontAwesomeOff="'fa-solid fa-pen-to-square fa-fw'" :onClick="editText" />
                <BaseBtn :lazyColorType="2" :fontAwesomeOn="'fa-solid fa-trash-can fa-fw'"
                    :fontAwesomeOff="'fa-solid fa-trash-can fa-fw'" :onClick="toggleDeleteDialog" />



                <BaseBtn :lazyColorType="3" :onClick="Deselect" :fontAwesomeOn="'fa-solid fa-circle-xmark fa-fw'"
                    :fontAwesomeOff="'fa-solid fa-circle-xmark fa-fw'" />
            </div>
        </v-sheet>

        <v-container fluid fill-height class="pa-0 ma-0">
            <v-row class="fill-height pa-0 ma-0">
                <!-- justify="center"  -->
                <v-col class="pa-0 ma-0">
                    <v-sheet :height="calContainerHeight" class="pa-0 ma-0">
                        <v-calendar ref="calendar" color="primary" type="week" :start="mondayToday"
                            :weekdays="[1, 2, 3, 4, 5, 6, 0]" :events="events" :event-color="getEventColor"
                            :event-ripple="false" event-overlap-mode="stack" locale="da-dk"
                            :first-interval="settings.startIntervalCalendar"
                            :interval-count="endIntervalCalendarLabelCalculated"
                            :interval-height="settings.intervalHeightValue" interval-width="30" @change="getEvents"
                            @mousedown:event="startDrag" @mousedown:time="startTime" @mousemove:time="mouseMove"
                            @mouseup:time="endDrag" @mouseleave.native="cancelDrag" @click:event="viewEvent">
                            <template v-slot:event="{ event, timed, timeSummary }">
                                <div class="v-event-draggable" @dblclick="editText">
                                    <div class="evt-name">{{ event.name }}</div>

                                    <div v-if="event.end - event.start > 900000" class="evt-time">{{ timeSummary() }} 
                                        <!-- <div>{{calculateTimeDifference(event.start,event.end)}}</div> -->
                                        <span v-if="StringHasChars(event.text)" class="evt-text">{{ event.text }}</span>


                                    </div>
                                    <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)">
                                    </div>
                                </div>
                            </template>
                        </v-calendar>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>



//  <div ref="textareaCon" class="">
//          <ComFroala
//           v-show="showFroalaEditor"
//           ref="ComFroala"
//           :text="text"
//           :readOnly="readOnly"
//           :showToolbar="showToolbar"
//           :handleClickTextEditor="handleClickTextEditor"
//           :onTextEditorFocussed="onTextEditorFocussed"
//           :onInitialized="showFroalaEditorMethod"
//           :onInputUpdated="textChanged"
//           :selectTaskItemComp="selectTaskItemComp"
//           :taskId="item.obj.id"
//         ></ComFroala>
//       </div> -->



//                                         <!-- <div ref="textareaCon" class="">
//         <ComFroala :text="'test'"  readOnly:true></ComFroala>
//       </div> -->
//                                         <!-- <span v-if="event.end - event.start >= 86400000">{{
//                         " (" +
//                         Math.round((event.end - event.start) / 86400000) +
//                         ") " +
//                         (Math.round((event.end - event.start) / 86400000) === 1
//                           ? "Day"
//                           : "Days") +
//                         ""
//                       }}</span> 
// import '../plugins/Froala/css/froala_editor.pkgd.min.scss';
// import '../plugins/Froala/VueFroalaCustom';
// import ComFroala from '../components/Other/ComFroala.vue';

export default {

    // components: {
    //     ComFroala
    // },
    data: () => ({
        name: "CalendarSchemaView",
        confirmText: "Are you sure you want to do this?",
        confirmTitle: "Confirmation",
        confirmAction: null,
        title: "",
        today: new Date(),
        calContainerHeight: 1000,
        showExtraMenu: false,
        showEditText: false,
        textareaTitle: "",
        textareaText: "",
        showDialogModel: false,
        settings: {
            intervalHeightValue: 120,
            startIntervalCalendar: 0,
            endIntervalCalendar: 24,
            nextMondaySwitch: false,
        },

        defaultColor: "#2196F3",

        colors: [
            "#2196F3",
            // "#3F51B5",
            "#673AB7",
            "#00BCD4",
            "#4CAF50",
            "#FF9800",
            "#757575",
        ],

        events: [],

        isDragging: false,
        currentEvent: null,
        lastEvent: null,

        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
    }),
    computed: {
        intervalHeightValueLabel() {
            return "Height:" + this.settings.intervalHeightValue.toString();
        },
        currentEventIntervalTimeDisplay() {
            const startDate = new Date(this.currentEvent.start);
            const endDate = new Date(this.currentEvent.end);
            const startTime = this.formatTime(startDate);
            const endTime = this.formatTime(endDate);
            const hours = this.calculateTimeDifference(
                this.currentEvent.start,
                this.currentEvent.end
            );

            return `${startTime} - ${endTime} (${hours})`;
        },
        startIntervalCalendarLabel() {
            return (
                "Start: " + String(this.settings.startIntervalCalendar).padStart(2, "0")
            );
        },
        endIntervalCalendarLabel() {
            return (
                "End: " + String(this.settings.endIntervalCalendar).padStart(2, "0")
            );
        },
        endIntervalCalendarLabelCalculated() {
            let end =
                24 -
                this.settings.startIntervalCalendar -
                (24 - this.settings.endIntervalCalendar);

            console.log("endIntervalCalendarLabelCalculated", end);
            return end;
        },
        mondayToday: function () {
            return (
                this.settings.nextMondaySwitch
                    ? this.getNextMondayAtMidnight
                    : this.getCurrentMondayAtMidnight
            )();
        },
        // endToday: function () {

        //   let end=(
        //     this.settings.nextMondaySwitch
        //       ? this.getNextMondayAtMidnight
        //       : this.getCurrentMondayAtMidnight
        //   )();

        //   end.setDate(end.getDate() + 5)

        //   console.log("endToday",end);
        //   return end;
        // },

        eventsInfo: function () {

            let time = 0;

            this.events.forEach((i) => {
                time += i.end - i.start;
            });

            const hours = Math.floor(time / (1000 * 60 * 60));
            const remainingMinutes = Math.floor(
                (time % (1000 * 60 * 60)) / (1000 * 60)
            );
            return (
                // this.mondayToday.toString() +
                "Events:(" +
                this.events.length +
                "), Hours " +
                hours +
                (remainingMinutes === 0
                    ? ""
                    : ":" + String(remainingMinutes).padStart(2, "0"))
            );
        },
        showCloneButton: function () {
            if (this.lastEvent === null || this.currentEvent === null) {
                return false;
            }

            if (
                this.lastEvent.start === this.currentEvent.start ||
                this.lastEvent.end === this.currentEvent.end
            ) {
                return false;
            }

            return true;
        },
    },

    mounted() {

        // hide scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = 'hidden';


        this.$refs.calendar.scrollToTime("08:00");


        // this.$refs.inputFileC.addEventListener("change",);

        let targetElement = document.querySelector(
            ".v-calendar-daily__intervals-head"
        );

        if (targetElement) {
            var absoluteElement = document.createElement("div");

            absoluteElement.textContent = "MENU";
            absoluteElement.style.cssText =
                "user-select: none; position: absolute;top: 50%;font-size:14px;    left: 50%;     transform: translate(-50%, -50%);    writing-mode: vertical-lr; text-orientation: upright;  white-space: nowrap; ";

            targetElement.style.backgroundColor = "#1e1e1e";

            targetElement.appendChild(absoluteElement);

            targetElement.addEventListener("click", this.clickedExtraMenu);
        } else {
            console.error("Element not found.");
        }

        this.handleResize();

        // Add a resize event listener when the component is mounted
        window.addEventListener("resize", this.handleResize);

        // Add a resize event listener for visualViewport when the component is mounted
        window.visualViewport.addEventListener("resize", this.handleResize);

        // const calContainer = this.$refs.calContainer;
        // let height = this.$refs.calContainer.offsetHeight;

        // console.log("calContainer",calContainer);

        // this.calContainerHeight=600;

        // this.$refs.calSheet.height=1000;

        console.log(this.name, "mounted => " + (elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));

    },


    beforeDestroy() {
        // Remove the resize event listener when the component is destroyed
        window.removeEventListener("resize", this.handleResize);
        window.visualViewport.removeEventListener("resize", this.handleResize);



        console.log(this.name, "beforeDestroy");


    },
    methods: {

        toggleDeleteDialog() {

            this.initConfirmDialog("Delete Event?", "Are you sure u want to delete <b>" + this.currentEvent.name + "</b> item?", this.deleteEvent);
        },
        toggleClearAllEvents() {

            this.initConfirmDialog("Clear All Events?", "Are you sure u want to delete all events?", this.clearAll);
        },
        initConfirmDialog(title, text, action) {

            this.confirmText = text;

            this.confirmTitle = title;

            this.confirmAction = action;

            this.showDialogModel = true;


            console.log("INIT DIALOG: ", title, this);

        },
        confirmEvent() {
            console.log('User clicked Yes');

            this.confirmAction();

            this.showDialogModel = false;
        },

        cancelConfirm() {

            console.log('User clicked No');

            this.showDialogModel = false;
        },

        updateEventText() {
            console.log("textarea", this.textareaText);

            this.currentEvent.text = this.textareaText;


            this.currentEvent.name = this.textareaTitle;





            this.showEditText = !this.showEditText;
        },
        editText(event) {

            this.textareaTitle = this.currentEvent.name;
            this.textareaText = this.currentEvent.text;
            this.showEditText = true;
        },
        formatTime(date) {
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            return `${hours}:${minutes}`;
        },
        StringHasChars(str) {
            return str === undefined || str === null || str.length === 0
                ? false
                : true;
        },
        clickedExtraMenu() {
            if (this.currentEvent) {
                this.currentEvent = null;
                this.showExtraMenu = true;
            } else {
                this.showExtraMenu = !this.showExtraMenu;
            }

            console.log("clicked Menu", this.showExtraMenu);
        },
        calculateTimeDifference(startTimestamp, endTimestamp) {
            const timeDifference = endTimestamp - startTimestamp;

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const remainingHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            // Round the minutes to the closest quarter hour (15, 30, 45, or 60 minutes)
            const roundedMinutes = Math.round(remainingMinutes / 15) * 15;

            let result = '';
            if (days > 0) {
                result += days + 'd ';
            }
            if (remainingHours > 0 || days > 0) {
                result += remainingHours + 'h ';
            }
            if (roundedMinutes > 0 || remainingHours > 0 || days > 0) {
                result += roundedMinutes + 'm';
            }

            return result.trim();
        },
        calculateTimeDifference2(startTimestamp, endTimestamp) {
            const timeDifference = endTimestamp - startTimestamp;

            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const remainingMinutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            );

            // Round the minutes to the closest quarter hour (15, 30, 45, or 60 minutes)
            const roundedMinutes = Math.round(remainingMinutes / 15) * 15;

            //   let str;

            // if(roundedMinutes==0 || roundedMinutes==60)
            // {
            //   str=  "¾";
            // }
            // else if(roundedMinutes==15)
            // {
            //   str=  "¾";
            // }
            // else if(roundedMinutes==30)
            // {
            //   str=  "¾";
            // }else if(roundedMinutes==45)
            // {
            //   str=  "¾";
            // }

            return roundedMinutes === 0
                ? hours.toString()
                : hours + ":" + roundedMinutes;
        },

        handleResize: function () {
            //

            let visibleHeight = window.visualViewport
                ? window.visualViewport.height
                : window.innerHeight;
            let appBarHeight = this.$vuetify.application.top;

            this.calContainerHeight = visibleHeight - appBarHeight;

            console.log("Resized:", this.calContainerHeight);
        },
        changeColorClick: function (event) {
            if (event.target.classList.contains("myzitem")) {
                const backgroundColor = window.getComputedStyle(
                    event.target
                ).backgroundColor;

                console.log("changeColorClick", backgroundColor);

                this.currentEvent.color = backgroundColor;
            }
        },
        titleChanged: function () {
            console.log("titleChanged");

            if (this.isNotNullOrUndefined(this.currentEvent)) {
                this.currentEvent.name = this.title;
            }
        },
        isNotNullOrUndefined: function (variable) {
            return Boolean(variable);
        },
        viewEvent: function () {
            console.log("viewEvent");
        },
        exportDataClick() {
            // const copiedObject = { ...this.events };
            // const copiedObject = Object.assign({}, this.events );
            let copiedObject = JSON.parse(JSON.stringify(this.events));

            copiedObject.forEach((e) => {
                e.start = e.start - this.mondayToday.getTime();

                e.end = e.end - this.mondayToday.getTime();
            });

            let outputObj = {
                settings: this.settings,
                events: copiedObject,
            };
            const blob = new Blob([JSON.stringify(outputObj)], {
                type: "application/json",
            });

            // Create a link element
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);

            const date = new Date();

            // Specify the locale you want, for example, 'en-US' (United States) or 'fr-FR' (French)
            const locale = "da-DK";

            // Options for date formatting (you can customize these based on your preferences)
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            // Format the date according to the specified locale and options
            const formattedDate = date.toLocaleDateString(locale, options);

            // Set the filename
            a.download = formattedDate.toString() + ".txt";

            // Append the link to the body
            document.body.appendChild(a);

            // Trigger a click on the link to initiate the download
            a.click();

            // Remove the link from the body
            document.body.removeChild(a);

            console.log("exported", outputObj);
        },
        importData: function (e) {
            console.log("importData() input change:", e);

            const file = e.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const jsonString = e.target.result;
                    let importedObject = JSON.parse(jsonString);

                    this.events = [];

                    importedObject.events.forEach((i) => {
                        i.start = i.start + this.mondayToday.getTime();

                        i.end = i.end + this.mondayToday.getTime();
                    });

                    console.log("Imported object events:", importedObject.events);

                    this.$set(this, "events", importedObject.events);

                    const keys1 = Object.keys(importedObject.settings);
                    const keys2 = Object.keys(this.settings);

                    // Check if both sets of keys are equal
                    let compare =
                        keys1.every((key) => keys2.includes(key)) &&
                        keys2.every((key) => keys1.includes(key));

                    if (compare) {
                        console.log("Settings Compare OK!");
                        const keysA = Object.keys(importedObject.settings);
                        for (const key of keysA) {
                            this.$set(this.settings, key, importedObject.settings[key]);

                            console.log(
                                "SUCCES $set => " + key,
                                " as " + importedObject.settings[key]
                            );
                        }
                    } else {
                        console.error(
                            "Settings doesnt match the current version, so import of that has skipped."
                        );
                    }

                    this.$refs.inputFileC.value = "";
                };

                reader.readAsText(file);
            }
        },
        importDataClick() {
            document.getElementById("inputfileC").click();
        },
        clearAll() {


            // @click="showEditText = !showEditText"
            console.log("THIS", this);
            this.$set(this, "events", []);
        },

        cloneEvent() {
            if (
                this.lastEvent.start !== this.currentEvent.start ||
                this.lastEvent.end !== this.currentEvent.end
            ) {
                this.events.push(this.lastEvent);
                console.log("cloned");
            }

            this.currentEvent = this.lastEvent;
            this.lastEvent = null;
        },
        Deselect() {
            this.currentEvent = null;
            this.lastEvent = null;
            this.showExtraMenu = false;
        },
        deleteEvent() {
            //

            const index = this.events.findIndex((event) =>
                Object.is(event, this.currentEvent)
            );

            if (index !== -1) {
                console.log(`Object found at index ${index}`);
                console.log(this.events[index]);
            } else {
                console.log("Object not found in the array");
                return;
            }

            // let msg =
            // if (!confirm(msg)) return;

            this.events.splice(index, 1);

            this.currentEvent = null;
            this.lastEvent = null;

            // this.$set(this, "currentEvent", null);
        },

        getCurrentMondayAtMidnight() {
            const today = this.today;
            const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
            const daysUntilMonday = dayOfWeek === 0 ? 6 : 1 - dayOfWeek; // Calculate days until next Monday
            const monday = new Date(today);
            monday.setDate(today.getDate() + daysUntilMonday);
            monday.setHours(0, 0, 0, 0); // Set time to midnight

            console.log("getCurrentMondayAtMidnight", monday);

            return monday;
        },
        getNextMondayAtMidnight() {
            const today = this.today;
            const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
            const daysUntilMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek; // Calculate days until next Monday
            let monday = new Date(today);
            monday.setDate(today.getDate() + daysUntilMonday);
            monday.setHours(0, 0, 0, 0); // Set time to midnight

            console.log("getNextMondayAtMidnight", monday);

            return monday;
        },
        getNextMondayAtMidnight2() {
            const today = new Date();
            const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
            const daysUntilMonday = dayOfWeek === 0 ? 6 : 1 - dayOfWeek; // Calculate days until current Monday
            let monday = new Date(today);
            monday.setDate(today.getDate() - daysUntilMonday);
            monday.setHours(0, 0, 0, 0); // Set time to midnight

            console.log("getNextMondayAtMidnight", monday);

            return monday;
        },
        startTime(tms) {
            const mouse = this.toTime(tms);
            this.isDragging = true;

            console.log("starttime", this.dragEvent && this.dragTime);

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;
                this.dragTime = mouse - start;
            } else {
                this.createStart = this.roundTime(mouse);

                this.createEvent = {
                    name: `Event ${this.events.length}`,
                    text: null,
                    color: this.defaultColor, //  this.rndElement(this.colors),
                    start: this.createStart,
                    end: this.createStart + 15 * 60000,
                    timed: true,
                };

                this.events.push(this.createEvent);
            }
        },
        extendBottom(event) {
            console.log("extendBottom");

            this.createEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
        },
        mouseMove(tms) {
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start;
                const end = this.dragEvent.end;
                const duration = end - start;
                const newStartTime = mouse - this.dragTime;
                const newStart = this.roundTime(newStartTime);
                const newEnd = newStart + duration;

                this.dragEvent.start = newStart;
                this.dragEvent.end = newEnd;
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false);

                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);

                if (min !== max) {
                    this.createEvent.start = min;
                    this.createEvent.end = max;
                }
            }
        },

        startDrag({ event, timed }) {
            if (event && timed) {
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;

                this.lastEvent = this.copyEvent(event);
                console.log("start drag", event);
            } else {
                console.log("start drag");
            }
        },
        copyEvent(e) {
            return Object.assign({}, e);
        },
        focusTitle() {
            this.$nextTick(() => {
                const textField = this.$refs.titleInputField;

                if (textField) {
                    textField.focus();
                }
            });
        },
        endDrag() {
            this.currentEvent = null;

            this.isDragging = false;

            let type = "";

            //Extend
            if (this.extendOriginal !== null && this.createEvent !== null) {
                type = "Extend";

                this.lastEvent = null;
                this.currentEvent = this.createEvent;
            }
            //New
            else if (this.createEvent !== null && this.dragEvent === null) {
                type = "New";

                this.lastEvent = null;
                this.currentEvent = this.createEvent;
            }
            //Move
            else if (this.createEvent === null && this.dragEvent !== null) {
                type = "Move";

                this.currentEvent = this.dragEvent;

                if (
                    this.lastEvent !== null &&
                    (this.lastEvent.start === this.currentEvent.start ||
                        this.lastEvent.end === this.currentEvent.end)
                ) {
                    this.lastEvent = null;
                } else if (this.lastEvent !== null) {
                    console.log("Last event is confirmed to be visible");
                }
            } else {
                this.lastEvent = null;
                this.currentEvent = null;
                type = "Canceled";
            }

            if (this.currentEvent !== null) {
                this.title = this.currentEvent.name;
                this.focusTitle();
            }

            console.log(
                "enddrag " + type,
                "create:",
                this.createEvent,
                "drag:",
                this.dragEvent,
                "extend:",
                this.extendOriginal
            );

            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
        },
        cancelDrag() {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal;
                } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                }
            }

            this.dragTime = null;
            this.createEvent = null;
            this.createStart = null;
            this.dragEvent = null;

            console.log("cancel drag");
        },
        roundTime(time, down = true) {
            const roundTo = 15; // minutes
            const roundDownTime = roundTo * 60 * 1000;

            return down
                ? time - (time % roundDownTime)
                : time + (roundDownTime - (time % roundDownTime));
        },
        toTime(tms) {
            return new Date(
                tms.year,
                tms.month - 1,
                tms.day,
                tms.hour,
                tms.minute
            ).getTime();
        },
        getEventColor(event) {
            const rgb = parseInt(event.color.substring(1), 16);
            const r = (rgb >> 16) & 0xff;
            const g = (rgb >> 8) & 0xff;
            const b = (rgb >> 0) & 0xff;

            return event === this.dragEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event === this.createEvent
                    ? `rgba(${r}, ${g}, ${b}, 0.7)`
                    : event.color;
        },
        getEvents({ start, end }) {
            if (this.events.length === 0) return;

            let cur = this.getCurrentMondayAtMidnight();

            let oldDate = cur;

            if (this.mondayToday.getTime() === cur.getTime()) {
                oldDate = this.getNextMondayAtMidnight();
            }

            console.log("Change:getEvents", oldDate, this.mondayToday);

            //change all timestamps to the new week date
            if (this.events.length !== 0) {
                // let copiedObject = JSON.parse(JSON.stringify(this.events));

                this.events.forEach((e) => {
                    e.start = e.start - oldDate.getTime() + this.mondayToday.getTime();

                    e.end = e.end - oldDate.getTime() + this.mondayToday.getTime();
                });
            }

            return;

            // const events = [];
            // console.log(start, end, "getEvents");

            // const min = new Date(`${start.date}T00:00:00`).getTime();
            // const max = new Date(`${end.date}T23:59:59`).getTime();

            // const days = (max - min) / 86400000;
            // const eventCount = this.rnd(days, days + 20);

            // for (let i = 0; i < eventCount; i++) {
            //   //  const timed = this.rnd(0, 3) !== 0
            //   const timed = true;
            //   // const timed = 3
            //   const firstTimestamp = this.rnd(min, max);
            //   const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
            //   const start = firstTimestamp - (firstTimestamp % 900000);
            //   const end = start + secondTimestamp;

            //   events.push({
            //     name: ("Event"  + this.events.length), //  this.rndElement(this.names),
            //     color: this.defaultColor, //  this.rndElement(this.colors),
            //     start,
            //     end,
            //     timed,
            //   });
            // }

            // this.events = events;
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },
        rndElement(arr) {
            return arr[this.rnd(0, arr.length - 1)];
        },
    },
};
</script>

<style>
.v-event-draggable {
    /* color:black !important; */
    white-space: normal;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.evt-name {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box;
      -webkit-line-clamp: 3; 
      -webkit-box-orient: vertical; */
}


.evt-time {
    font-size: 14px;

}

.evt-text {
    color: #dadbd9;
    /* white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.v-event-draggable {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 3px !important;
    height: 100%;
}

.v-calendar .v-event-timed {
    overflow: hidden;
}

.v-calendar-daily__interval-text {
    font-size: 20px !important;
    top: -14px !important;
}

.v-event-draggable,
.v-event-timed,
.v-calendar-daily__day-interval,
.v-event-summary strong {
    user-select: none;
}

.v-event-timed-container {
    margin-right: 0px !important;
}
</style>
<!-- <style>
html {
 overflow: hidden; 

}
</style> -->
<style scoped lang="scss">
.myzcontainer {
    width: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0;
}

.myzfarve1 {
    background-color: rgb(33, 150, 243);
}

.myzfarve2 {
    background-color: rgb(103, 58, 183);
}

.myzfarve3 {
    background-color: rgb(76, 175, 80);
}

.myzfarve4 {
    background-color: rgb(117, 117, 117);
}

.myzfarve5 {
    background-color: rgb(255, 152, 0);
}

.myzfarve6 {
    background-color: rgb(227, 42, 208);
}

.myzitem {
    border: 1px solid #000;
    height: 26px;
    text-align: center;
}

#inputfileC {
    display: none;
}

.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
        display: none;
        position: absolute;
        left: 50%;
        height: 4px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: "";
    }

    &:hover::after {
        display: block;
    }
}
</style>