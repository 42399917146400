import axios from 'axios'


// axios.defaults.baseURL = "https://lalageek.com:3123";

// axios.defaults.timeout = 5000;

// headers: {
//     "Cache-Control": "no-cache",
//     "Content-Type": "application/x-www-form-urlencoded",
//   },

// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common["Access-Control-Allow-Origin"], "*";



axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded';


export default axios