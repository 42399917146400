let youtubeApi = function (apiKey, log) {

    if (log === undefined) {
        console.warn("No logger provided. Defaulting to console");
        log = console;

    }

    if (apiKey === undefined) {
        log.error("Empty apiKey. Aborting import of youtubeApi");
        return;


    }

    const youtubeApi = {

        // getPlaylistItemsAndDetailedInformation: function (uploadPlaylistId, callback, onerror) {


        //     let orignalCallbacks = {
        //         callback: callback,
        //         onerror: onerror,
        //     };

        //     let localCallback = function (videos) {

        //         console.log("this",this);
        //         this.getDetailedVideoInformation(videos, orignalCallbacks.callback, orignalCallbacks.onerror);
        //     };

        //     localCallback["orignalCallbacks"] = orignalCallbacks;

        //     this.getPlaylistItems(uploadPlaylistId, localCallback, onerror);


        // },

        getPlaylistItems: function (uploadPlaylistId, callback, onerror) {


            let apiCall = "getPlaylistItems";

            if (!uploadPlaylistId) {
                log.error("Empty uploadPlaylistId. Aborting " + apiCall);
                return;
            }

            let url = "https://www.googleapis.com/youtube/v3/playlistItems?key=" + apiKey + "&playlistId=" + uploadPlaylistId + "&maxResults=50&part=snippet,contentDetails,status";

            // log("getPlaylistItems",url);

            fetch(url)
                .then(response => {

                    log(apiCall, "fetch status: " + response.status + ((response.statusText + "").length === 0 ? "" : (" txt: " + response.statusText)));
                    return response.json();
                })
                .then(data => {

                    // dnGkH2ypFLE

                    log(apiCall, 'Response Data:', data);

                    let videosObj = {};
                    data.items.forEach(video => {
                        // Extract video ID and title
                        const s = video.snippet;

                        videosObj[s.resourceId.videoId] = {
                            channelId: s.channelId,
                            playlistId: s.playlistId,
                            id: s.resourceId.videoId,
                            kind: s.resourceId.kind,
                            title: s.title,
                            description: s.description,
                            position: s.position,
                            publishedAt: s.publishedAt,
                            thumbnails: {
                                default: {
                                    height: s.thumbnails.default.height,
                                    width: s.thumbnails.default.width,
                                    url: s.thumbnails.default.url,
                                },
                                high: {
                                    height: s.thumbnails.high.height,
                                    width: s.thumbnails.high.width,
                                    url: s.thumbnails.high.url,
                                },
                                maxres: {
                                    height: s.thumbnails.maxres.height,
                                    width: s.thumbnails.maxres.width,
                                    url: s.thumbnails.maxres.url,
                                },
                                medium: {
                                    height: s.thumbnails.medium.height,
                                    width: s.thumbnails.medium.width,
                                    url: s.thumbnails.medium.url,
                                },
                                standard: {
                                    height: s.thumbnails.standard.height,
                                    width: s.thumbnails.standard.width,
                                    url: s.thumbnails.standard.url,
                                },

                            }

                        }


                    });

                    callback(videosObj);
                    // this.getDetailedVideos(videosObj);

                    //  this.visitedObjects = [];
                    //  const serializedData = encodeURIComponent(JSON.stringify(this.vidz, this.JSONreplacer)); //, "\t"

                    // // this.setCookie(this.cookieName, serializedData);

                    //  log("setCookie", serializedData);

                })
                .catch(error => {

                    onerror(error);
                    log.error('Error fetching videos:', error);
                });

        },
        getDetailedVideoInformation: function (videosObj, callback, onerror) {


            let apiCall = "getDetailedVideoInformation";

            let getIdStringFromArray = function (input, callback, onerror) {

                let array;



                if (typeof input === 'object') {
                    const idString = Object.keys(input).join(',');

                    // console.log("idString", idString);

                    return idString;

                }
                else if (Array.isArray(input) && input.length !== 0) {
                    array = input;

                    const ids = array.map(item => item.id);

                    const idString = ids.join(',');


                    // console.log("idString", idString);


                    return idString;

                }
                else {

                    return "";
                }




            }

            let ids = getIdStringFromArray(videosObj);


            let url = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=' + ids + '&key=' + apiKey;



            fetch(url)
                .then(response => {

                    log(apiCall, "fetch status:", response.status, " txt: ", response.statusText, response);


                    this.statusCode = response.status;
                    this.statusText = response.statusText;

                    return response.json();
                })
                .then(data => {

                    log(apiCall, 'getDetailedVideos() Response data:', data);

                    let finalVidz = [];
                    data.items.forEach(video => {
                        // Extract video ID and title
                        const s = video.snippet;

                        let vid = videosObj[video.id];

                        let dur = youtubeApi.parseISO8601Full(video.contentDetails.duration);
                        // log("deliciousString", dur.deliciousString);
                         vid["duration"] = dur;

                        finalVidz.push(vid);



                    });



                    callback(finalVidz);

                    // for (let col = 0; col < 4; col++) {

                    //     this.rowsInChunks[col] = this.calcChunksInRows(this.vidz, (col + 1));

                    // }

                    // log('Video Objects Parsed', this.vidz, this.rowsInChunks);

                    // this.resizeEvent();
                })
                .catch(error => {


                    log.error(apiCall, 'Error fetching videos:', error);
                });



        },
        parseISO8601Full: function (durationString) {

            // const durationString = "P1Y2M3W4DT5H6M7S";
            // const totalSeconds = parseDuration(durationString);
            // console.log(totalSeconds); // Output: 37211867 seconds

            const regex = /P(?:([-+]?\d+Y)?([-+]?\d+M)?([-+]?\d+W)?([-+]?\d+D)?)?(T(?:([-+]?\d+H)?([-+]?\d+M)?([-+]?\d+S)?)?)?/;
            const matches = durationString.match(regex);

            const years = parseInt(matches[1] || 0, 10);
            const months = parseInt(matches[2] || 0, 10);
            const weeks = parseInt(matches[3] || 0, 10);
            const days = parseInt(matches[4] || 0, 10);
            const hours = parseInt(matches[6] || 0, 10);
            const minutes = parseInt(matches[7] || 0, 10);
            const seconds = parseInt(matches[8] || 0, 10);

            const totalSeconds = (years * 31536000) + (months * 2592000) + (weeks * 604800) + (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;

            var buildStr = function (str, val) {

                let s = "";

                if (val === 0) {
                    s = ""
                }
                else if (val === 1) {
                    s = val + " " + str.slice(0, -1) + ", ";
                }
                else {
                    s = val + " " + str + ", ";
                }

                return s;
            }

            let str = "";
            str += buildStr("years", years);
            str += buildStr("months", months);
            str += buildStr("weeks", weeks);
            str += buildStr("days", days);
            str += buildStr("hours", hours);
            str += buildStr("mins", minutes);
            str += buildStr("secs", seconds);

            if (str.endsWith(", ")) {
                str = str.slice(0, -2);
            }

            return {
                totalSeconds: totalSeconds,
                seconds: seconds,
                minutes: minutes,
                hours: hours,
                days: days,
                weeks: weeks,
                months: months,
                years: years,
                deliciousString: str,

            };

        }



    }

    return youtubeApi;

}



export default youtubeApi;