<template>
  <div class="ilb" @touchstart="touchstart" @touchend="mouseup" @mousedown="mousedown" @mouseup="mouseup"
    @mouseenter="hoverEnter" @mouseleave="hoverLeave" @click.stop="clickLocal">
    <!-- :style="{background: linear-gradient(to bottom,tomato 50%, rgb(160, 33, 10) 50%)}" -->
    <!--     :class="mouseStatus" toggleColor ? clsOn : clsOff -->
    <div class="divBtn" :class="buttonClasses">


      <i v-if="fontAwesomeOn.length" :class="toggleColor ? fontAwesomeOn : fontAwesomeOff"
        class="px-0 mx-o py-0 my-o" />



      <slot v-if="imageSlot"></slot>

      <!-- <img v-if="imageUrl!==null" :src="imageUrl" width="45px" height="45px" alt="Logo"> -->


      <slot v-if="!imageSlot">{{ text }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleColor: { type: Boolean, required: false, default: true },
    text: { type: String, default: "" },
    classOn: { type: String, default: "" },
    classOff: { type: String, default: "" },
    fontAwesomeOn: { type: String, default: "" },
    fontAwesomeOff: { type: String, default: "" },
    size: { type: Number, default: 5 },
    disabled: { type: Boolean, required: false, default: false },
    lazyColorType: { type: Number, required: false, default: 1 },
    imageSlot: { type: Boolean, required: false, default: false },
    returnMeThis: { default: null },

    onClick: {
      type: Function,
      required: false,
      default() {
      },
    },
  },
  data() {
    return {
      buttonClasses: ""

    };
  },
  watch: {
    lazyColorType: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        // do your stuff

        this.color = "color" + val;
        this.backColor = "backColor" + val;

        this.setDefaultClasses();
      }
    },
    disabled: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        // do your stuff
        // console.log("DISABLED")
        //  this.color = "color"+ val;
        // this.backColor = "backColor"+ val;

        this.setDefaultClasses();
      }
    }
  },
  beforeMount() {

    this.color = "color" + this.lazyColorType;
    this.backColor = "backColor" + this.lazyColorType;

    this.setDefaultClasses();
  },


  methods: {
    setDefaultClasses() {

      if (!this.disabled)
        this.buttonClasses =
          "size" + this.size + " " + this.color + " " + this.backColor;
      else this.buttonClasses =
        "disabled size" + this.size + " " + this.color + " " + this.backColor;


    },
    mousedown() {

      if (this.disabled) return;
      this.setDefaultClasses();
      this.buttonClasses += " backColorPress" + this.lazyColorType;

    },
    mouseup() {
      if (this.disabled) return;
      this.setDefaultClasses();
    },

    touchstart() {
      if (this.disabled) return;
      this.setDefaultClasses();
      this.buttonClasses += " backColorPress hoverEnter" + this.lazyColorType;

    },
    hoverEnter() {
      if (this.disabled) return;
      this.setDefaultClasses();
      this.buttonClasses += " hoverEnter" + this.lazyColorType;
    },
    hoverLeave() {

      if (this.disabled) return;
      this.setDefaultClasses();
    },
    clickLocal: function () {
      if (this.disabled)
        return;


      if (this.returnMeThis != null) {

        this.onClick(this.returnMeThis);
      }
      else {
        this.onClick();
      }

    },
  },
};
</script>

<style scoped lang="scss">
/* Green  */
.color1 {
  color: #caccd0;
}

.backColor1 {
  background-color: #00994c;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
}

.backColorPress1 {
  background-color: rgba(17, 102, 70, 0.8);
}

.hoverEnter1 {
  color: rgb(124, 118, 118);
}

/* Red */
.color2 {
  color: #caccd0;
}

.backColor2 {
  background-color: tomato;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
}

.backColorPress2 {
  background-color: rgba(160, 33, 10, 0.8)
}

.hoverEnter2 {

  color: rgb(124, 118, 118);
}

/* Silver/whiteish   */
.color3 {
  color: #caccd0;
}

.backColor3 {
  background-color: rgb(142, 147, 159);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
}

.backColorPress3 {
  background-color: rgb(97, 100, 105);
}

.hoverEnter3 {

  color: rgb(124, 118, 118);

}

/* TOP BUTTON OFF */
.color5 {
  color: #caccd0;
}

.backColor5 {
  background-color: rgb(142, 147, 159);
  background-color: #546e7a;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;

}

.backColorPress5 {

  background-color: rgba(60, 80, 112, 0.8);
}

.hoverEnter5 {

  color: rgb(124, 118, 118);

}

/* TOP BUTTON ON */
.color6 {
  color: #caccd0;
  color: #a3c4e6;


  // color: #d8dbbc;

}

.backColor6 {

  background-color: scale-color($selectionBack, $lightness: -50%);

  box-shadow: rgb(214 222 230 / 40%) 0px 0px 0px 2px !important;
  border: 1px solid silver;
  margin: 3px !important;
  // background-color: rgba(60, 80, 112, 0.8);

}

.backColorPress6 {

  // background-color: rgba(111, 125, 185, 0.8);
  // background-color: rgba(77, 101, 139, 0.8);

  background-color: scale-color(#3c5070, $lightness: 30%);
}

.hoverEnter6 {

  color: rgb(124, 118, 118);

}

/* Expand - plus/minus button */
.color7 {
  color: black;
}

.backColor7 {
  background-color: #eccfa8;
  border-radius: 8px !important;
  border: 1px solid rgb(70, 70, 70) !important;
  box-shadow: none;



}

.backColorPress7 {

  background-color: scale-color(#eccfa8, $lightness: -40%);
  //  box-shadow: blue !important;

  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 1px;
}

.hoverEnter7 {

  background-color: scale-color(#eccfa8, $lightness: -20%);
  // color: rgb(124, 118, 118);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 1px;

}

/* small / expand button */
.size6 {

  font-size: 11px !important;
  /* padding: 5px 15px;
  margin: 5px; */
  /* padding-left:6px;
  padding-right:6px; */
  /* padding-top:2px;
      padding-bottom:2px; */

}

.size6 i {

  font-size: 11px;
  /* padding: 5px 15px;
  margin: 5px; */
}

.size1 {
  font-size: 10px;
  padding: 2px 4px;

}

.size2 {
  font-size: 14px;
  padding: 2px 4px;

  margin: 6px 3px 0px 3px;
}

.size3 {
  font-size: 16px;

  margin: 4px;
}

.size3 i {
  font-size: 14px;
  margin: 2px 4px;


}

.size5 {
  font-size: 16px;
  padding: 5px 15px;
  margin: 5px;
}


.divBtn {
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  font-family: inherit;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;

  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
}

.divBtn.disabled {
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;

  background-color: rgba(39, 41, 44, 0.8);


  opacity: 0.4;
}
</style>