<template>
    <div>
        <!-- {{ lang() }} -->
        {{ lang }}
        <p>Danish Time: {{ danishTime }}</p>
        <p>EST Time: {{ estTime }}</p>
        <p>Next payday => {{ countDownDateString }}</p>

        <p>
            {{ countdown.days }} days, {{ countdown.hours }} hours, {{ countdown.minutes }} minutes, {{ countdown.seconds }}
            seconds
        </p>
    </div>
</template>
  
<script>
export default {
    props: {
        lang: {
            type: String, required: true,
        },
    },

    data() {
        return {
            value: 1,
            name:"InfoView",
            countdown: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            countDownDateString: "",
            danishTime: '',
            estTime: '',
            initialTime: Date.now(),
            initialPaydayTime: this.calculateNextDanishPayday() // Calculate Danish payday dynamically
        };
    },
    mounted()
    {
       // hide scrollbar
      let elHtml = document.getElementsByTagName('html')[0];
      elHtml.style.overflowY = 'hidden';

      console.log(this.name,"mounted => "+ (elHtml.style.overflowY === "hidden" ? "Hide Scrollbar":"Show Scrollbar" ) );
 
    },
    created() {
        // Update clocks every second
        this.updateClocks();

        const paydate = new Date(this.initialPaydayTime);

        this.countDownDateString = this.getDayOfWeek(paydate) + " (" + paydate.getDate() + "-" + paydate.getMonth()+")";
        // Adjust setInterval dynamically to maintain precision
        //   this.clockTimer = setInterval(this.updateClocks, 1000);


    },
    destroyed() {
        // Clear clock timer when component is destroyed
        clearInterval(this.clockTimer);
    },
    methods: {
        updateClocks() {
            const now = Date.now();
            this.danishTime = this.formatTime(now, 'Europe/Copenhagen');
            this.estTime = this.formatTime(now, 'America/New_York');

            // Calculate elapsed time since initial calculation
            const elapsedTime = now - this.initialTime;

            // Update countdown based on elapsed time
            this.updateCountdown(elapsedTime);

            // Adjust setInterval dynamically
            const timeLost = Date.now() - now;
            clearInterval(this.clockTimer);
            this.clockTimer = setInterval(this.updateClocks, 1000 - timeLost);
        },
        updateCountdown(elapsedTime) {
            // Calculate time difference for next payday
            const timeDifference = this.initialPaydayTime - Date.now();

            // Calculate remaining days, hours, minutes, and seconds
            this.countdown.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            this.countdown.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.countdown.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            this.countdown.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        },
        formatTime(timestamp, timeZone) {
            const options = {
                timeZone: timeZone,
                hour12: false,
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };
            let formattedTime = new Date(timestamp).toLocaleString('da-DK', options);
            formattedTime = formattedTime.replace(",", "");

            return formattedTime.replace(/\//g, '-');
        },
        // Function to calculate the next Danish payday dynamically
        calculateNextDanishPayday() {
            // Get current date
            const currentDate = new Date();
            // Set date to the last day of the current month
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            let nextPayday = new Date(lastDayOfMonth);
            // If the last day of the month is a weekend, adjust to the previous Friday or Thursday
            if (nextPayday.getDay() === 6) { // Saturday
                nextPayday.setDate(lastDayOfMonth.getDate() - 1); // Previous Friday
            } else if (nextPayday.getDay() === 0) { // Sunday
                nextPayday.setDate(lastDayOfMonth.getDate() - 2); // Previous Thursday
            }
            // Set time to midnight
            nextPayday.setHours(0, 0, 0, 0);
            return nextPayday.getTime();
        },
        getDayOfWeek(date) {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayIndex = date.getDay();
            return daysOfWeek[dayIndex];
        }

    }
};
</script>
  
<style scoped>
/* Add styles as needed */
</style>
  