<template>
    <div>
        <p>Prop value: {{ propValue }}</p>
        <p>Computed property value: {{ computedPropertyValue }}</p>
        <button @click="log2">Click to log</button>
        <p>Counter: {{ counter }}</p>
    </div>
</template>

<script>

import lalalogger from "@/plugins/lalalogger.js"
let getlog = lalalogger.init("EmptyTemplate");


export default {
    props: {
        propValue: {
            type: String,
            required: false,
            default: "someProp"
        },
    },

    data() {
        return {
            counter: 0,
        };
    },
    computed: {
        computedPropertyValue() {
            return this.propValue + ' - ' + this.counter;
        }
    },
    beforeCreate() {

        const log = getlog("beforeCreate");
        log();
    },
    created() {

        const log = getlog("created");
        log();
    },
    beforeMount() {
        const log = getlog("beforeMount");
        log();
    },
    mounted() {

        const log = getlog("mounted");
        log();
    },
    beforeUpdate() {

        const log = getlog("beforeUpdate");
        log();
    },
    updated() {

        const log = getlog("updated");
        log();
    },
    beforeDestroy() {

        const log = getlog("beforeDestroy");
        log();
    },
    destroyed() {

        const log = getlog("destroyed");
        log();

        if (getlog) {
            getlog = null;
        }
    },
    // METHODS
    methods: {
        log2() {

            const log = getlog("log2");
            log("log2");
        },

    },
};
</script>
<!-- Scoped Styles -->
<style scoped></style>

<!-- Global Styles -->
<!-- <style>
</style> -->
