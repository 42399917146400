const lalalogger = {
   
    init(componentName) {

        // let opt;

        // if(options)
        // {
        //     opt=options;
        // }
        // else{
        //     opt={
        //         debug:true,
        //     }
        // }

        const cNameDic = {};

        cNameDic[componentName] = {};

        const modifiedComponentName = componentName.endsWith('.vue') ? componentName : `${componentName}.vue`;

        const trimmedComponentName = modifiedComponentName.slice(0, 25);


        const remainingComponentSpaces = Math.max(0, 25 - trimmedComponentName.length);


        cNameDic[componentName] = {
            str: `${trimmedComponentName}${' '.repeat(remainingComponentSpaces)}`
        };
        var loggingEnabled=true;

        var off=function(){

            loggingEnabled=false;
        }

        var on=function(){
            loggingEnabled=true;

        }
       var getlog=function (methodName, logInit) {


            let retXXXZZZ;

            const obj = cNameDic[componentName];

            if (obj[methodName]) {
                retXXXZZZ = `${obj.str} => ${obj[methodName]}`;

                // if (opt.debug) {

                //     console.log("Found method:", retXXXZZZ);
                // }
            } else {
                // Modify the method name
                // methodName = methodName.charAt(0).toUpperCase();
                const modifiedMethodName = methodName.endsWith('()') ? methodName : `${methodName}()`;

                const trimmedMethodName = modifiedMethodName.slice(0, 25);
                const remainingMethodSpaces = Math.max(0, 25 - trimmedMethodName.length);

                obj[methodName] = `${trimmedMethodName}${' '.repeat(remainingMethodSpaces)}`;

                retXXXZZZ = `${obj.str} => ${obj[methodName]}`;

                if(logInit)
                {
                    console.log(retXXXZZZ);
                }
                // if (opt.debug) {

                //     console.log("Creating method:", retXXXZZZ);
                // }
              
            }   


            
        
            // Define the returned function
            let loggingMethod = function () {
                
                if(loggingEnabled===false)
                return;
              
                let argsXXXX = Array.from(arguments);
       
                argsXXXX.unshift(retXXXZZZ);      
                console.log.apply(console, argsXXXX);
            };

            // Define the returned function
            let loggingMethodError = function () {

                if(loggingEnabled===false)
                return;

                // Convert arguments into an array
                let argsXXXX = Array.from(arguments);
                // Prepend the loggingString to the arguments
                argsXXXX.unshift(retXXXZZZ);
                // Use Function.prototype.apply to pass the arguments array to console.log
                console.error.apply(console, argsXXXX);
            };
            loggingMethod["off"]=off;
            loggingMethod["on"]=on;         
            loggingMethod["getlog"]=getlog;

            loggingMethod["err"] = loggingMethodError;
            loggingMethod["error"] = loggingMethodError;

            return loggingMethod;

            // return {

            //     log:loggingMethod,
            //     err:loggingMethodError,
            //     error:loggingMethodError,
            // };

            
        }   


        // getlog["getlog"]=getlog;

        getlog["off"]=off;
        getlog["on"]=on;    
        getlog["getlog"]=getlog;    
        return getlog
        // let ffs={};

        // function getlogInit (methodName, logInit) {

        //     let gl=getlog(methodName, logInit);


            
        //     return {

        //         log:loggingMethod,
        //         err:loggingMethodError,
        //         error:loggingMethodError,
        //     };

          
        // };

        // gl["getlog"] = getlogInit;
       


        // return  function getlog()
        //         {
        //             getlogString(methodName)


        //             function err () {
        //                 // Convert arguments into an array
        //                 let argsXXXX = Array.from(arguments);
        //                 // Prepend the loggingString to the arguments
        //                 argsXXXX.unshift(retXXXZZZ);
        //                 // Use Function.prototype.apply to pass the arguments array to console.log
        //                 console.error.apply(console, argsXXXX);
        //             };
        //             function error () {
        //                 // Convert arguments into an array
        //                 let argsXXXX = Array.from(arguments);
        //                 // Prepend the loggingString to the arguments
        //                 argsXXXX.unshift(retXXXZZZ);
        //                 // Use Function.prototype.apply to pass the arguments array to console.log
        //                 console.error.apply(console, argsXXXX);
        //             };
        

        //         }

        
        

        //     // Define the returned function
        //     let loggingMethod = function () {
        //         // Convert arguments into an array
        //         let argsXXXX = Array.from(arguments);
        //         // Prepend the loggingString to the arguments
        //         argsXXXX.unshift(retXXXZZZ);
        //         // Use Function.prototype.apply to pass the arguments array to console.log
        //         console.log.apply(console, argsXXXX);
        //     };

        //     // Define the returned function
        //     let loggingMethodError = function () {
        //         // Convert arguments into an array
        //         let argsXXXX = Array.from(arguments);
        //         // Prepend the loggingString to the arguments
        //         argsXXXX.unshift(retXXXZZZ);
        //         // Use Function.prototype.apply to pass the arguments array to console.log
        //         console.error.apply(console, argsXXXX);
        //     };

            

        //     loggingMethod["getlog"] = getlog;

        //     loggingMethod["err"] = loggingMethodError;
        //     loggingMethod["error"] = loggingMethodError;


        //     return loggingMethod;

        // getlogString

        // getlog["getlog"]=getlog;

         

  

    },

};

export default lalalogger;



// const cNameDic = {};s

// const helper = {

//   initLogHelper(componentName) {

//     const cNameDic = {};

//     cNameDic[componentName] = {};

//     const modifiedComponentName = componentName.endsWith('.vue') ? componentName : `${componentName}.vue`;

//     const trimmedComponentName = modifiedComponentName.slice(0, 25);


//     const remainingComponentSpaces = Math.max(0, 25 - trimmedComponentName.length);


//     cNameDic[componentName] = {
//       str: `${trimmedComponentName}${' '.repeat(remainingComponentSpaces)}`
//     };

//     return {
//       dic: cNameDic,
//       getlog: function (methodName) {


//         let retXXXZZZ;

//         const obj = cNameDic[componentName];

//         if (obj[methodName]) {
//           retXXXZZZ = `${obj.str} => ${obj[methodName]}`;
//           //  console.log(1);
//         } else {
//           // Modify the method name
//           // methodName = methodName.charAt(0).toUpperCase();
//           const modifiedMethodName = methodName.endsWith('()') ? methodName : `${methodName}()`;

//           const trimmedMethodName = modifiedMethodName.slice(0, 25);
//           const remainingMethodSpaces = Math.max(0, 25 - trimmedMethodName.length);

//           obj[methodName] = `${trimmedMethodName}${' '.repeat(remainingMethodSpaces)}`;

//           retXXXZZZ = `${obj.str} => ${obj[methodName]}`;
//           //  console.log(2);
//         }



//         // Define the returned function
//         let loggingMethod = function () {
//           // Convert arguments into an array
//           let argsXXXX = Array.from(arguments);
//           // Prepend the loggingString to the arguments
//           argsXXXX.unshift(retXXXZZZ);
//           // Use Function.prototype.apply to pass the arguments array to console.log
//           console.log.apply(console, argsXXXX);
//         };

//         // Define the returned function
//         let loggingMethodError = function () {
//           // Convert arguments into an array
//           let argsXXXX = Array.from(arguments);
//           // Prepend the loggingString to the arguments
//           argsXXXX.unshift(retXXXZZZ);
//           // Use Function.prototype.apply to pass the arguments array to console.log
//           console.error.apply(console, argsXXXX);
//         };

//         loggingMethod["err"]=loggingMethodError;

//           return loggingMethod;
        
        
//       }
//     };
//   },


//   // getLogStr(componentName, methodName) {


//   //   let ret;

//   //   const obj = cNameDic[componentName];

//   //   if (obj) {
//   //     if (obj[methodName]) {
//   //       ret = `${obj.str} => ${obj[methodName]}`;
//   //       // console.log(1);
//   //     } else {
//   //       // Modify the method name
//   //       methodName = methodName.charAt(0).toUpperCase();
//   //       const modifiedMethodName = methodName.endsWith('()') ? methodName : `${methodName}()`;

//   //       const trimmedMethodName = modifiedMethodName.slice(0, 25);
//   //       const remainingMethodSpaces = Math.max(0, 25 - trimmedMethodName.length);

//   //       obj[methodName] = `${trimmedMethodName}${' '.repeat(remainingMethodSpaces)}`;

//   //       ret = `${obj.str} => ${obj[methodName]}`;
//   //       // console.log(2);
//   //     }
//   //   } else {
//   //     // Modify the component and method names


//   //     methodName = methodName.charAt(0).toUpperCase();


//   //     const modifiedComponentName = componentName.endsWith('.vue') ? componentName : `${componentName}.vue`;
//   //     const modifiedMethodName = methodName.endsWith('()') ? methodName : `${methodName}()`;

//   //     const trimmedComponentName = modifiedComponentName.slice(0, 25);
//   //     const trimmedMethodName = modifiedMethodName.slice(0, 25);

//   //     const remainingComponentSpaces = Math.max(0, 25 - trimmedComponentName.length);
//   //     const remainingMethodSpaces = Math.max(0, 25 - trimmedMethodName.length);

//   //     cNameDic[componentName] = {
//   //       str: `${trimmedComponentName}${' '.repeat(remainingComponentSpaces)}`
//   //     };

//   //     cNameDic[componentName][methodName] = `${trimmedMethodName}${' '.repeat(remainingMethodSpaces)}`;

//   //     ret = `${cNameDic[componentName].str} => ${cNameDic[componentName][methodName]}`;

//   //     // console.log(3);
//   //   }

//   //   return ret;
//   // },

// };

// Vue.prototype.$initLogHelper = helper.initLogHelper;


// Vue.prototype.$log = function (methodName) {

//   let componentName = "YourComponentName"; // Assuming you have componentName defined somewhere

//   let loggingString = helper.getLogStr(`${componentName}`, methodName);

//   // Define the returned function
//   let logger = function () {
//     // Convert arguments into an array
//     let args = Array.from(arguments);
//     // Prepend the loggingString to the arguments
//     args.unshift(loggingString);
//     // Use Function.prototype.apply to pass the arguments array to console.log
//     console.log.apply(console, args);
//   };

//   // Return the logger function
//   return logger;
// },

  // console.log(helper.getLogStr("componentName","SomeRandomMethod"),"log some other shit");

  // console.log(helper.getLogStr("componentName","SomeRandomMethod"),"log some other shit");

  // console.log(helper.getLogStr("componentNameThatIsLonger","SomeShort"),"log some other shit");

  // console.log(helper.getLogStr("componentNameThatIsLonger","SomeShort"),"log some other shit");

  // console.log(helper.getLogStr("componentSht","largeLongMethodOMG"),"log some other shit");

  // console.log(helper.getLogStr("componentSht","largeLongMethodOMG"),"log some other shit");

  // console.log(helper.getLogStr("componentBlaaaaaaaah","largeLongMethodOMGiamreal"),"log some other shit");

  // console.log(helper.getLogStr("componentBlaaaaaaaah","largeLongMethodOMGiamreal"),"log some other shit");


