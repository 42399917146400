<template>
  <div
    class="ilb"
    @touchstart="touchstart"
    @touchend="touchend"
    @touchcancel="touchcancel"
    @touchmove="touchmove"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @mouseenter="hoverEnter"
    @mouseleave="hoverLeave"
  >
    <!-- :style="{background: linear-gradient(to bottom,tomato 50%, rgb(160, 33, 10) 50%)}" -->
    <!--     :class="mouseStatus" toggleColor ? clsOn : clsOff -->
    <div class="divBtn" :class="buttonClasses">
      <i
        v-if="fontAwesomeOn.length"
        :class="toggleColor ? fontAwesomeOn : fontAwesomeOff"
        :style="{ fontSize: fSize + 'px' }"
        class="px-0 mx-o py-0 my-o"
      />

      <slot>{{ text }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleColor: { type: Boolean, required: false, default: true },
    toggleColorLong: { type: Boolean, required: false, default: false },
    text: { type: String, default: "" },
    classOn: { type: String, default: "" },
    classOff: { type: String, default: "" },
    fontAwesomeOn: { type: String, default: "" },
    fontAwesomeOff: { type: String, default: "" },
    returnMeThis: { default: null },
    onClick: { type: Function, required: false, default() {} },

    size: { type: Number, default: 5 },
    fSize: { type: Number, default: 18 },
    holdTime: { type: Number, default: 1000 },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      clsOn: "",
      clsOff: "",
      buttonClasses: "",
      mouseIsDown: null,

      mouseStatus: "mouseOff",
      isMobile: false,
    };
  },
  watch: {
    disabled: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        // do your stuff
        // console.log("DISABLED")
        //  this.color = "color"+ val;
        // this.backColor = "backColor"+ val;

        this.setClasses();
      },
    },
  },
  mounted() {
    // if (this.classOn.length === 0) this.clsOn = "bColorOn";
    // else this.clsOn = this.classOn;

    // if (this.classOff.length === 0) this.clsOff = "bColorOff";
    // else this.clsOff = this.classOff;

    // this.clsOn += " size" + this.size;
    // this.clsOff += " size" + this.size;
    this.setClasses();
  },

  methods: {
    setClasses() {
      if (this.classOn.length === 0) this.clsOn = "bColorOn";
      else this.clsOn = this.classOn;

      if (this.classOff.length === 0) this.clsOff = "bColorOff";
      else this.clsOff = this.classOff;

      this.clsOn += " size" + this.size + " " + this.mouseStatus;
      this.clsOff += " size" + this.size + " " + this.mouseStatus;

      if (!this.disabled)
        this.buttonClasses = this.toggleColor ? this.clsOn : this.clsOff;
      else this.buttonClasses = "disabled size" + this.size;
    },
    clickLocal: function () {
      if (this.disabled) return;

      this.mouseStatus = "mouseOff hoverLeft";
      this.setClasses();

      if (this.returnMeThis != null) {
        this.onClick(this.returnMeThis);
      } else {
        this.onClick();
      }
    },

    touchstart() {
      if (!this.isMobile) this.isMobile = true;

      if (this.disabled) return;

      if (this.toggleColorLong) this.mouseStatus = "mouseHold3 hoverLeft";
      else if (!this.toggleColor) this.mouseStatus = "mouseHold hoverLeft";
      else if (this.toggleColor) this.mouseStatus = "mouseHold2 hoverLeft";

      this.setClasses();

      //   console.log("touchstart");

      this.mouseIsDown = setTimeout(() => {
        //  console.log("MENU OPENED!!!!");

        this.clickLocal();
      }, this.holdTime);
    },
    touchend() {
      // console.log("touchend");

      this.clearButtonStatus();
    },
    touchcancel() {
      // console.log("touchcancel");
      this.clearButtonStatus();
    },

    touchmove() {
      //  console.log("touchmove");
      this.clearButtonStatus();
    },

    mousedown() {
      if (this.disabled || this.isMobile) return;

      // if (!this.toggleColor) this.mouseStatus = "mouseHold hoverLeft";
      // else this.mouseStatus = "mouseHold2 hoverLeft";

      if (this.toggleColorLong) this.mouseStatus = "mouseHold3 hoverLeft";
      else if (!this.toggleColor) this.mouseStatus = "mouseHold hoverLeft";
      else if (this.toggleColor) this.mouseStatus = "mouseHold2 hoverLeft";

      this.setClasses();

      //console.log("mousedown");

      this.mouseIsDown = setTimeout(() => {
        //  console.log("MENU OPENED!!!!");

        this.clickLocal();
      }, this.holdTime);
    },
    mouseup() {
      if (this.disabled || this.isMobile) return;

      //  console.log("mouseup");

      this.clearButtonStatus();
    },
    hoverEnter() {
      if (this.disabled || this.isMobile) return;

      this.mouseStatus = " hoverEnter";

      // console.log("hoverEnter");
      this.setClasses();
      clearTimeout(this.mouseIsDown);
    },
    hoverLeave() {
      if (this.disabled || this.isMobile) return;

      // console.log("hoverLeave");

      this.clearButtonStatus();
    },
    // clearButtonStatus
    clearButtonStatus() {
      this.mouseStatus = "mouseOff hoverLeft";

      this.setClasses();
      clearTimeout(this.mouseIsDown);
    },
  },
};
</script>

<style scoped lang="scss">
/* https://dev.to/flyingduck92/creating-fill-background-animation-for-button-using-css-2me3 */
.mouseHold {
  background: linear-gradient(
    to bottom,
    tomato 50%,
    rgba(160, 33, 10, 0.8) 50%
  );

  background-size: 100% 200%;

  background-position: bottom left;
  // color: rgb(54, 53, 53);

  color: #caccd0;
  // color: rgba(6, 24, 44, 0.4);
  box-shadow: rgba(1, 2, 3, 0.6) 0px 0px 0px 2px;
  transition: all 1s ease-out;
}
.mouseHold3 {
  background: linear-gradient(
    to bottom,
    tomato 50%,
    rgba(160, 33, 10, 0.8) 50%
  );

  background-size: 100% 200%;

  background-position: bottom left;
  // color: rgb(54, 53, 53);

  color: #caccd0;
  // color: rgba(6, 24, 44, 0.4);
  box-shadow: rgba(1, 2, 3, 0.6) 0px 0px 0px 2px;
  transition: all 3s ease-out;
}
.mouseHold2 {
  background: linear-gradient(
    to bottom,
    #00994c 50%,
    rgba(17, 102, 70, 0.8) 50%
  );
  background-size: 100% 200%;

  background-position: bottom left;
  // color: rgb(54, 53, 53);

  // color: rgba(6, 24, 44, 0.4);
  color: #caccd0;
  box-shadow: rgba(1, 2, 3, 0.6) 0px 0px 0px 2px;
  transition: all 1s ease-out;
}
.hoverEnter {
  // box-shadow: $selectionBack 0px 0px 0px 2px;

  // box-shadow: rgb(34, 33, 33) 0px 0px 0px 2px;
  color: #caccd0;

  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
  color: rgb(124, 118, 118);
}

.hoverLeft {
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
  //   rgba(6, 24, 44, 0.65) 0px 4px 6px -1px;
  //box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
}

.mouseOff {
  background-position: top left;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;
  color: #caccd0;
}

.divBtn.size0 {
  // padding: 0px 5px 0px 5px;
  width: 20px;
  height: 20px;
}
.size0 {
  font-size: 5px !important;
  padding: 3px 2px;
  margin-right: 0px;

  margin-left: 0px;
  margin-top: 2px;
  margin-bottom: 4px;
}
.size0 i {
  font-size: 14px !important;
  width: 16px !important;
  height: 16px !important;
}

.size1 {
  font-size: 5px;
  padding: 2px 4px;
}
.size1 {
  font-size: 10px;
  padding: 2px 4px;
}
.size2 {
  font-size: 14px;
  padding: 2px 4px;

  margin: 6px 3px 0px 3px;
}
.size3 {
  font-size: 16px;
  padding: 2px 4px;

  margin: 4px;
}
.size5 {
  font-size: 16px;
  padding: 5px 15px;
  margin: 5px;
}
.bColorOn {
  background-color: #00994c;
}
.bColorOff {
  background-color: tomato;
}

.divBtn {
  display: inline-block;

  border-radius: 5px;
  text-decoration: none;
  font-family: inherit;

  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
    user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
}
.divBtn.disabled {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px;

  background-color: rgba(39, 41, 44, 0.8);

  opacity: 0.4;
}
</style>