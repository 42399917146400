<template>
    <div>
        <v-container fluid>
            <v-row v-for="(row, rowIndex) in numRows" :key="rowIndex">
                <v-col v-for="(item, columnIndex) in columns" :key="columnIndex" class="column">

                    <template v-if="item=items[rowIndex * columns + columnIndex]">          
                            {{ columnIndex }}    {{ item.title }} <span>publishedAt: {{ $utcToDate(item.publishedAt,lang) }} {{ item.kind }}</span>

                     <ShowMoreText v-if="item.description" :text="item.description" :lang="lang" />
                  
                      <img :src="item.thumbnails.maxres.url" width="100%" height="auto">
                    </template>
                    <template v-else>             
                        <div class="empty-column"><slot></slot></div>
                    </template>
                    <!--                    
   
                    <div v-if="o !== null">

                        {{ item.title }} <span>publishedAt: {{ item.publishedAt }} {{ item.kind }}</span>
                        <ShowMoreText v-if="item.description" :text="item.description" :lang="lang" />
                  
                        <img :src="item.thumbnails.maxres.url" width="100%" height="auto">


                    </div>
                    <div v-else>
                        <slot></slot>
                    </div> -->

                </v-col>
            </v-row>
        </v-container>

        <!-- <img :src="item.thumbnails.high.url" :width="item.thumbnails.high.width":height="item.thumbnails.high.height"> -->

        <!-- <iframe :width="iwidth" :height="iheight" type="text/html" :src="embedUrl(item.id)" frameborder="0" allowfullscreen></iframe> -->
        <!--                         
        <v-container fluid>
            <v-row v-for="(cols, chunkIndex) in getRows" :key="chunkIndex">
                <v-col v-for="(o, itemIndex) in cols" :key="itemIndex">

                    <div v-if="o !== null">

                        {{ item.title }} <span>publishedAt: {{ item.publishedAt }} {{ item.kind }}</span>
                        <ShowMoreText v-if="item.description" :text="item.description" :lang="lang" />
                  
                        <img :src="item.thumbnails.maxres.url" width="100%" height="auto">


                    </div>
                    <div v-else>
                        <slot></slot>
                    </div>

                </v-col>
            </v-row>
        </v-container> -->


        <div v-if="apiErrorMessage.length != 0">

            YouTube API could not be loaded right now. {apiErrorMessage}
        </div>
    </div>
</template>

<script>

import lalalogger from "@/plugins/lalalogger.js"
let getlog = lalalogger.init("RowColComp");


export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        apiErrorMessage: {
            type: String,
            required: true,
        },
        
        lang: {
            type: String,
            required: true,
        },
        


    },
    data() {
        return {
            counter: 0,
            rowsInChunks: [4],
        };
    },
    computed: {

        numRows: function () {
            return Math.ceil(this.items.length / this.columns);
        },
        // getRows: function () {
        //     let log = getlog("getRows");

        //     log("getRows", this.columns);

        //     // this.setVideos(videosObj);

        //     // for (let col = 0; col < 4; col++) {

        //     //     this.rowsInChunks[col] = this.calcChunksInRows(this.vidz, (col + 1));

        //     // }

        //     return this.rowsInChunks[this.columns - 1];
        // },

    },

    // computed: {
    //     computedPropertyValue() {
    //         return this.propValue + ' - ' + this.counter;
    //     }
    // },
    // beforeCreate() {

    //     const log = getlog("beforeCreate");
    //     log();
    // },
    // created() {

    //     const log = getlog("created");
    //     log();
    // },
    // beforeMount() {
    //     const log = getlog("beforeMount");
    //     log();
    // },
    // mounted() {

    //     const log = getlog("mounted");
    //     log();
    // },
    // beforeUpdate() {

    //     const log = getlog("beforeUpdate");
    //     log(this.columns);
    // },
    // updated() {

    //     const log = getlog("updated");
    //     log();
    // },
    // beforeDestroy() {

    //     const log = getlog("beforeDestroy");
    //     log();
    // },
    // destroyed() {

    //     const log = getlog("destroyed");
    //     log();

    //     if (getlog) {
    //         getlog = null;
    //     }
    // },
    // METHODS
    // methods: {
    //     calcChunksInRowsxxx(items, numColumns) {

    //         const numRows = Math.ceil(items.length / numColumns);

    //         //  let log=getlog("calcChunksInRows");
    //         //  log("numRows", numRows)

    //         const filledItems = [];


    //         for (let row = 0; row < numRows; row++) {

    //             const rowItems = [];

    //             // this.rowsInChunks[row]


    //             // this.$set(this.rowsInChunks, row, []);


    //             for (let col = 0; col < numColumns; col++) {

    //                 const index = row * numColumns + col;

    //                 if (index < items.length) {
    //                     rowItems.push(items[index]);
    //                 } else {
    //                     // Insert dummy item if the index exceeds the number of actual items
    //                     rowItems.push(null);
    //                 }
    //             }

    //             filledItems.push(rowItems);

    //             // this.rowsInChunks[row].push(rowItems);
    //         }

    //         return filledItems;


    //     },

    // },
};
</script>
<!-- Scoped Styles -->
<style scoped></style>

<!-- Global Styles -->
<!-- <style>
</style> -->
