<template>
  <div class="ilb">
    <label v-if="labelName.length !== 0" :for="type"
      >{{ labelName }}:</label
    >

    <!-- autocomplete="off" -->
    <span v-if="readOnly">{{ value }}</span>
    <input
      ref="input"
      v-else
      :id="type"
      :value="value"
 
      :name="type"
      :type="type"
      :autocomplete="autocomplete" 
      :placeholder="placeholder"
      class="select px-2"
      @input="change"
      @keydown.enter.prevent="submit"
    />
  </div>

        <!-- @input="$emit('input', $event.target.value)" -->
</template>
     
  
<script>

export default {
  name: "BaseInput",
  props: {
    // inputMode: { type: String, default: "text" },
    type: { type: String, default: "text",  required:false },
    placeholder: { type: String, default: "Type text here.." },
    labelName: { type: String, default: "" },
    value: { type: String, required: true, default: "" },
    autocomplete: { type: String, required: false, default: "" },
    readOnly: { type: Boolean, default: false },
    change: {
      type: Function,
      required: false,
      default: function () {
        // console.log("change event =>",this.value);
      },
    },
    submit: {
      type: Function,
      required: false,
      default: function () {
      },
    },
  },
  data: function () {
    return {
      focusEnabled: this.$jslib.toBool(
        process.env.VUE_APP_FOCUS_DIRECTIVE_BOOL
      ),
      // focusEnabled: false,
    };
  },
  // beforeCreate: function () {
  //   this.readonlyLocal = this.readOnly;
  //   this.readonlyLocal = this.$store.getters.globalEditable;
  // },
  mounted() {
    //this.$emit("value", this.value);
    this.tryFocus();
  },

  methods: {
    tryFocus() {
      if (!this.readOnly) this.$refs.input.focus();
    },
    // input() {
    //   console.log("input", this.localText);
    // },
    // change() {
    //   console.log("change", this.localText);
    // },
    // updateInput(val) {
    //   console.log("updateInput out", this.value);
    //   //  this.$emit("value", this.value);
    // },
    //   onClickButton (event) {
    //     console.log("Child");
    //      this.$emit('clicked', 'someValue')
    //  },
    // taskSelected() {
    //   console.log("taskSelected");
    // },
  },
};
</script>

<style scoped>
label {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 20px;
}

input {
  font-size: 16px;
}
input:focus {
  outline: none !important;
  border: none;
  line-height: 2;
  font-size:16px
}

</style>