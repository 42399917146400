import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  // theme: { disable: true },

  
  // theme: {
 
  //   dark: true,
  //   options: {
   
   
  //     customProperties: true,
  //   },

  //   themes: {
  //     light: {
  //       primary: '#007BFF',
  //       secondary: '#424242',
  //       accent: '#82B1FF',
  //       error: '#FF5252',
  //       info: '#2196F3',
  //       success: '#4CAF50',
  //       warning: '#FFC107',
  //       anchor: '#8c9eff',
  //     },
  //     dark: {
  //       primary: '#873e23',
  //       secondary: '#424242',
  //       accent: '#82B1FF',
  //       error: '#FF5252',
  //       info: '#2196F3',
  //       success: '#4CAF50',
  //       warning: '#FFC107',
  //       anchor: '#8c9eff',
  //     },
  //   },
  // },
  // asdsada
  theme: {
    disable: true,
    dark: true,
    options: {
   
      disable: true,
      customProperties: true,
    },

    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#8c9eff',
      },
      dark: {
        primary: '#873e23',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#8c9eff',
      },
    },
  },
});


