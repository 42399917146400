<template>
    <div>
        <!-- <h1 @click="setMaxCols">Cols: {{ maxCols }}, {{ screenWidth }}px, lang: {{ lang }}</h1> -->
        <RowColComp v-if="maxCols !== null" :items="vidz" :apiErrorMessage="apiErrorMessage" :columns="maxCols" :lang="lang" />

    </div>
</template>
<script>
import lalalogger from "@/plugins/lalalogger.js"
import youtubeApi from "@/plugins/youtubeApi.js"
import RowColComp from "@/components/RowColComp.vue"

const componentName = "aZtraLEnForceR";
let getlog = lalalogger.init(componentName);

    // (getlog("disableLog")).off();

export default {

    components: {
        RowColComp
    },
    props: {
        lang: {
            type: String, required: true,
            //  default: "da-DK"
        },
    },

    data: () => ({
        // iwidth: 640,
        // iheight: 360,
        //  visitedObjects: [],
        // cookieName: "aztralenforcerYoutube",
        // GET UPLOAD ID UUNqb9UH9sJ5f6jYWPfjIjxQ AZtral
        name: "aZtralEnForceR",
        // https://youtube.googleapis.com/youtube/v3/channels?part=contentDetails&id=UPLOAD_ID&key=KEY
        apiKey: "AIzaSyBLwQY8MeCBepFUw8TCgompWN1cdzZf3F0",
        uploadId: "UUNqb9UH9sJ5f6jYWPfjIjxQ",
        // videos: [
        //     "ZH6tTBRB9g4",
        //     "Zjummn3ap6Y",
        //     "WJMXmiBZnPk",
        //     "dnGkH2ypFLE"
        // ],


        vidz: [],
        maxCols: null,

        screenWidth: null,
        resizeTimeout: null,


        api: null,
        apiErrorMessage: "",
        // totalRows:null,

    }),

    beforeMount() {

        let log = getlog("beforeMount");

        log("Init youtubeApi");

        this.api = youtubeApi(this.apiKey, getlog("youtubeApi"));

        log("Init youtubeApi", this.api);


        let Placeholder = {
            getDetailedVideoInformation: this.api.getDetailedVideoInformation,
            setVideos2: this.setVideos2,
            onerror: this.onerror,
        }

        function callMeBack(v) {

            Placeholder.getDetailedVideoInformation(v, Placeholder.setVideos2, Placeholder.onerror);

        }

        // this.api.getPlaylistItemsAndDetailedInformation(this.uploadId, this.setVideos2, this.onerror);
        this.api.getPlaylistItems(this.uploadId, callMeBack, this.onerror);


    },
    mounted() {
        window.addEventListener("resize", this.resizeEvent);

        // Show scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = null;

        let log = getlog("mounted");
        log((elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"), "addEventListener: resize")


    },



    beforeDestroy() {

        window.removeEventListener("resize", this.resizeEvent);

        let log = getlog("beforeDestroy");
        log("removeEventListener: resize");

    },
    methods: {
        setMaxCols() {
            
            const randomNumber = Math.floor(Math.random() * 4) + 1;

             this.maxCols = randomNumber;
        },
        setVideos(videosObj) {

            // this.vidz.splice(0, 0, (Object.values(videosObj)));


            this.vidz = Object.values(videosObj);

            console.log("setvideos", this.vidz);

        },
        setVideos2(videosObj) {

            let log = getlog("setVideos2");
            log(videosObj);

            this.vidz = videosObj;

            log('rowsInChunks:', this.rowsInChunks);


            this.resizeEvent();
        },
        onerror(err) {
            let log = getlog("onerror");

            this.apiErrorMessage = "asd";

            log("onerror", err);
        },
        resizeEvent() {

            clearTimeout(this.resizeTimeout);

            this.resizeTimeout = setTimeout(() => {

                this.handleResize();

            }, 100);

        },
        handleResize() {

            const screenWidth = window.innerWidth;



            if (screenWidth >= 1200) {
                // Large screens
                this.maxCols = 4; // 3 columns for the first 4 items, then full width
            } else if (screenWidth >= 1024) {
                this.maxCols = 3;
            }
            else if (screenWidth >= 768) {
                // Small screens
                this.maxCols = 2;
            }
            else {
                this.maxCols = 1;
            }



            let log = getlog("handleResize");
            log('Resized! screenWidth:', screenWidth, ", maxCols: ", this.maxCols);

            this.screenWidth = screenWidth;

        },



        embedUrl(id) {
            return `https://www.youtube.com/embed/${id}`;
        },

        // Define the replacer function
        JSONreplacer(key, value) {

            // console.log(key, value);

            if (typeof value === 'object' && value !== null) {
                if (this.visitedObjects.includes(value)) return; // Handle circular references
                this.visitedObjects.push(value);
            }

            // Omit properties that start with an underscore
            if (key.startsWith('_')) return;

            // Omit properties with null or undefined values
            if (value === null || value === undefined) return;

            if (key === 'title' || key === 'description') {
                return encodeURIComponent(value)
            }

            else if (key === 'thumbnails') {
                return {
                    default: {
                        height: value.default.height,
                        width: value.default.width,
                        url: encodeURIComponent(value.default.url),
                    },
                    high: {
                        height: value.high.height,
                        width: value.high.width,
                        url: encodeURIComponent(value.high.url),
                    },
                    maxres: {
                        height: value.maxres.height,
                        width: value.maxres.width,
                        url: encodeURIComponent(value.maxres.url),
                    },
                    medium: {
                        height: value.medium.height,
                        width: value.medium.width,
                        url: encodeURIComponent(value.medium.url),
                    },
                    standard: {
                        height: value.standard.height,
                        width: value.standard.width,
                        url: encodeURIComponent(value.standard.url),
                    },
                };
            }


            // Return the original value for other properties
            return value;
        },
        JSONreviver(key, value) {
            // Decode URI-encoded values for specific keys
            if (key === 'title' || key === 'description') {
                return decodeURIComponent(value);
            }

            // Decode URI-encoded URLs for thumbnail properties
            if (key === 'thumbnails' && typeof value === 'object' && value !== null) {
                const decodedThumbnails = {};
                for (const thumbnailSize in value) {
                    if (Object.prototype.hasOwnProperty.call(value, thumbnailSize)) {
                        decodedThumbnails[thumbnailSize] = {
                            height: value[thumbnailSize].height,
                            width: value[thumbnailSize].width,
                            url: decodeURIComponent(value[thumbnailSize].url),
                        };
                    }
                }
                return decodedThumbnails;
            }

            return value;
        },

        // parseCookie(cookieString) {

        //     var cookies = {};
        //     cookieString.split(';').forEach(function (cookie) {
        //         var parts = cookie.split('=');
        //         var name = parts[0].trim();
        //         var value = decodeURIComponent(parts[1]);
        //         cookies[name] = value;
        //     });
        //     return cookies;
        // },

        // setCookie(name, cData) {


        //     const expirationDate = new Date();

        //     expirationDate.setDate(expirationDate.getDate() + 1);


        //     const cookieString = `aztralenforcerYoutube=${encodeURIComponent(cData)}; expires=${expirationDate.toUTCString()}; path=/`;

        //     document.cookie = cookieString;
        //     // document.cookie = name + "=" + cData;// + "; max-age=" + (24 * 60 * 60) + ";";
        // },
        // getCookie(cookieName) {

        //     const cookies = document.cookie.split(';');
        //     console.log("cookies",cookies);

        //     for (const cookie of cookies) {
        //         const [name, value] = cookie.trim().split('=');

        //         if (name === cookieName) {

        //             return decodeURIComponent(value);
        //         }
        //     }


        //     return null;
        // },

        // isDataOlderThan24Hours(cookieName) {
        //     var cookieTimestamp = document.cookie.replace(new RegExp('(?:(?:^|.*;\\s*)' + cookieName + 'Timestamp\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1');

        //     if (cookieTimestamp) {
        //         var timestamp = parseInt(cookieTimestamp);
        //         var currentTime = new Date().getTime();
        //         var twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
        //         return (currentTime - timestamp) > twentyFourHoursInMillis;
        //     } else {
        //         // If there's no timestamp, assume data is older than 24 hours
        //         return true;
        //     }
        // },

    }

};
</script>
<style scoped>
.limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
}
</style>