<template>
  <v-app id="app" class="">

    <!-- absolute -->
    <v-main >

      <!-- TOP BAR -->
      <v-app-bar app color="primary" class="pr-1 pl-4">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"><i class="fa-solid fa-bars"></i></v-app-bar-nav-icon>

        <!-- hidden element to control click event -->
        <div class="topMenuClick posFix r0 t0 b0" @click.stop="showLangMenu = !showLangMenu">
        </div>
        <!-- just the graphics of the top right gear menu -->
        <div class="posAbs topMenuBg z0">
        </div>

        <div class="d-flex  align-center justify-space-between w100">

          <v-toolbar-title class="pl-2 noSel">
            {{ selectedRouteName }}
          </v-toolbar-title>
          <!-- LOGO LaLaGeeK.com -->



          <div class="posAbs topMenuContent z1">
            <i class="fa-solid fa-gear fa-lg posAbs r0 t0 z1 topMenuGears"></i>
          </div>

          <div id="logoContainer" class="d-flex align-center noSel" @click="navigateToRoute(menuItems[0])">
            <img src="@/assets/logo/LalaGeekLogo.png" alt="Logo" class="z1" style="height:50px;width:auto" />
            <!-- LaLa + Geek Container -->
            <div class="d-flex flex-column mr-0 posRel">
              <img class="posAbs" :src='"@/assets/lang/" + this.languageJs + "emoji.png"' width="20px" height="20px"
                style="transform: rotate(35deg); opacity:1; top:4px; left:-25px; opacity: 0.5; ">
              <!-- La La -->
              <div class="d-flex align-center justify-center">

                <span class="mr-2 grey--text text--lighten-1" style="transform: rotate(340deg);font-size:18px">La</span>
                <span class="grey--text text--lighten-1" style="transform: rotate(20deg);font-size:22px">La</span>

              </div>
              <!-- GeeK -->
              <div class="blue-grey--text z1" style="font-size:22px">
                <span class="letterSpace">G</span>
                <span class="letterSpace">e</span>
                <span class="reversed letterSpace">e</span>
                <span class="letterSpace">K</span>
              </div>

            </div>
            <!-- dotCom deep-orange--text text--darken-4-->
            <span class="dotCom"><span class="dot">&#x2022;</span><span class="grey--text darken-2">com</span></span>

          </div>

        </div>
      </v-app-bar>
      <!-- Top right Menu -->
      <div class="text-center">
        <!-- overlay-color="#0f9747" -->
        <v-dialog v-model="showLangMenu" width="500">


          <v-card>
            <v-card-title class="bg1">
              Privacy Policy
            </v-card-title>

            <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </v-card-text>


            <!-- Languages -->

            <span class="hest" v-for="(language, index) in languages" :key="index">

              <v-hover v-slot="{ hover }">

                <div class="dib" :class="{ 'on-hover': hover, 'on-selected': language === languageJs }">
                  <img @click="setLang(language)" class="curPoint" :src='"@/assets/lang/" + language + "emoji.png"'
                    width="100px" height="100px" style="">
                </div>

              </v-hover>

            </span>


            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showLangMenu = false">
                I accept
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- APP BAR: MAIN MENU OPENED -->

      <v-navigation-drawer v-model="drawer" app  temporary class="drawer-width">
        <v-list>
          <div v-for="(item, index) in menuItems" :key="index" @click="navigateToRoute(item)">
            <div v-if="!isMenuExcluded(item)">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr v-if="item.break == true">
            </div>
          </div>
        </v-list>


      </v-navigation-drawer>


      <!-- <v-container>
        <v-select v-model="selectedRoute" :items="$router.options.routes" label="Select a route" item-text="name"
          item-value="path" @change="navigateToRoute"></v-select>
      </v-container> -->

      <!-- <router-link :to="{ name: 'Info View', params: { lang: this.languageJs }}">User</router-link> -->


      <!-- <router-view name="aZtralEnForceR" :lang="languageJs"/> -->


      <router-view></router-view>

      <!-- <router-view v-slot="{ Component }">
       
        <component :is="Component" />

      </router-view>  -->



    </v-main>


  </v-app>
</template>
<script>

import lalalogger from "@/plugins/lalalogger.js"
let getlog = lalalogger.init("App");

// getlog.off();
export default {
  name: "App",
  data: () => ({
    showLangMenu: false,
    languageJs: "da-DK",
    // languageJs: "en-US",
    languages: ["da-DK", "en-US"],
    overflowClass: "",
    overflowToggle: true,
    drawer: false,
    selectedRouteName: null,
    selectedRoute: null,
    menuItems: [],
    excludedMenus: ["/ErrorView"],
    userAgentString: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36"

  }),
  //  computed: {
  //   ninja()
  //   {
  //     return this.languageJs
  //   }
  // },
  updated() {
    let log = getlog("updated");




    let targetPath = window.location.pathname;



    if (targetPath === "")
      targetPath = "/";

    log("targetPath:", targetPath);

    let targetRoute;


    for (const route of this.$router.options.routes) {
      if (route.path === targetPath) {
        targetRoute = route;

        this.selectedRouteName = route.name;
        this.selectedRoute = route;


      }

    }



  },

  beforeMount() {

    let log = getlog("beforeMount");

    this.menuItems = this.$router.options.routes;



    let targetPath = window.location.pathname;


    if (targetPath === "")
      targetPath = "/";


    log("targetPath:", targetPath);

    let targetRoute;
    let errorRoute;

    for (const route of this.$router.options.routes) {
      if (route.path === targetPath) {
        targetRoute = route;

        this.selectedRouteName = route.name;
        this.selectedRoute = route;
      }

      if (route.path === "/ErrorView") {
        errorRoute = route;
      }
    }



    log("targetRoute:", targetRoute);

    if (!targetRoute) {

      this.selectedRouteName = errorRoute.name;
      this.selectedRoute = errorRoute;


      // log("Route not found, defaulting to Home");
      window.location.href = window.location.origin + "/ErrorView";
      return;
    }



    const language = this.getQueryParameter("lang");


    if (language != null) {

      log('Setting language from query:', language);

      this.setLang(language);

    }
    else {
      log('No language in query. Defaulting:', this.languageJs);

      this.setLang(this.languageJs);

    }


  },

  methods: {
    isMenuExcluded(route) {


      for (let i = 0; i < this.excludedMenus.length; i++) {

        if (route.path === this.excludedMenus[i]) {
          return true;
        }
      }

      return false;
    },
    setQueryParameter(key, value) {
      const searchParams = new URLSearchParams(window.location.search);

      // Check if the parameter already exists
      if (searchParams.has(key)) {
        // Override existing parameter
        searchParams.set(key, value);
      } else {
        // Add new parameter
        searchParams.append(key, value);
      }

      // Construct the new URL with updated query parameters
      const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;

      // Replace the current URL with the new URL
      window.history.replaceState({}, '', newUrl);
    },
    getQueryParameter(key) {
      const searchParams = new URLSearchParams(window.location.search);

      // Check if the parameter already exists
      if (searchParams.has(key)) {
        // Override existing parameter


        return searchParams.get(key);
      }
      return null;
    },


    setLang(language) {
      let log = getlog("setLang");

      if (!this.languages.includes(language)) {

        log("Language:" + language + ", not found. Defaulting to:", this.languages[0]);
        language = this.languages[0];


      }

      this.languageJs = language;
      this.setQueryParameter("lang", this.languageJs);

      this.selectedRoute.props["lang"] = this.languageJs;

      log("Language Set:", this.languageJs);

    },

    navigateToRoute(route) {

      const log = getlog("navigateToRoute");


      if (this.$router.currentRoute.path === route.path) {


        if (this.selectedRoute.props["lang"] == !this.languageJs) {
          this.selectedRoute.props["lang"] = this.languageJs;
          log("Updating language on routeProps", route);
        }
        else {
          log("Already at destination", route);

        }


      }
      else {

        this.selectedRouteName = route.name;
        this.selectedRoute = route;

        this.selectedRoute.props["lang"] = this.languageJs;

        log("Navigating to:", route);
        this.$router.push({ path: route.path, query: { lang: this.languageJs } })
      }





    },


  },



};

// something() {
//       const axios = require('axios');

//       let config = {
//         headers: {
//           'User-Agent': this.userAgentString,
//         },
//       };


//       config = {
//         "headers": {
//           "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8",
//           "Accept-Encoding": "gzip, deflate, br",
//           "Accept-Language": "en-US,en;q=0.5",
//           "Host": "httpbin.org",
//           "Sec-Fetch-Dest": "document",
//           "Sec-Fetch-Mode": "navigate",
//           "Sec-Fetch-Site": "none",
//           "Sec-Fetch-User": "?1",
//           "Upgrade-Insecure-Requests": "1",
//           "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:123.0) Gecko/20100101 Firefox/123.0",
//           "X-Amzn-Trace-Id": "Root=1-65d6059f-4b22a7892f59241c2f501907"
//         }
//       }


//       config = {
//         "Accept": "application/json, text/plain, */*",
//         "Accept-Encoding": "gzip, deflate, br",
//         "Accept-Language": "en-US,en",
//         "Host": "httpbin.org",
//         "Origin": "http://lalageek.com",
//         "Referer": "http://lalageek.com/",
//         "Sec-Ch-Ua": "\"Not A(Brand\";v=\"99\", \"Brave\";v=\"121\", \"Chromium\";v=\"121\"",
//         "Sec-Ch-Ua-Mobile": "?0",
//         "Sec-Ch-Ua-Platform": "\"Windows\"",
//         "Sec-Fetch-Dest": "empty",
//         "Sec-Fetch-Mode": "cors",
//         "Sec-Fetch-Site": "cross-site",
//         "Sec-Gpc": "1",
//         "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
//         "X-Amzn-Trace-Id": "Root=1-65d6061f-6e2c4094553a8d2168d0f2ee"
//       }

//       let url='https://httpbin.org/headers'
//       url='https://www.youtube.com/@aztralenforcer'
//       axios.get(url,config)
//         .then(({ data }) => {
//           console.log(data)
//       let log=getlog("setLang");
//       log("Language change to:", this.languageJs);

//         });

//     },
// async scrapeYouTube() {
//   try {

//     const userAgentString = navigator.userAgent;

//     // Set the user agent string in the Axios request headers
//     const options = {
//       headers: {
//         'User-Agent': userAgentString
//       }
//     };



//     // Make the Axios request
//     const response = await axios.get('https://www.youtube.com/', options);

//     // Parse the HTML response and extract information
//     console.log(response.data); // This will print the HTML content of the page
// let log=getlog("setLang");
//   log("Language change to:", this.languageJs);
//   } catch (error) {
//     console.error('Error:', error);
//   }
// },
</script>



<!-- SCOPED STYLES -->
<style scoped>
@media (max-width: 600px) {
  .drawer-width {
    width: 100vw !important;
    /* Set drawer width to full screen on small devices */
  }
}

.drawer-width {
  width: 600px !important;
}

/* Top corner menu */

#logoContainer {

  cursor: url('assets/cursor/curHome32.png') 16 16, url('assets/cursor/curHome32.png') 0 0, pointer;
}

.topMenuGears {
  color: white;
  color: #6b6b6b;
  color: #5c5b5b;
  top: 12px;
  /* width: 25px;
  height: 25px; */
}

.topMenuClick {
  z-index: 10;
  /* height:100%; */
  width: 40px;

  opacity: 0.3;

  cursor: url('assets/cursor/curGear32.png') 16 16, url('assets/cursor/curGear32.png') 0 0, pointer;

}

.topMenuContent {
  top: 0;
  right: 0;
  pointer-events: none;
  /* background-color: rgba(255, 0, 0,1 ); */

}

.topMenuBg {
  /* display:none; */
  top: 0px;
  right: -55px;
  width: 100px;
  height: 40px;
  top: 0px;
  right: -55px;
  /* background-color: rgb(69, 131, 115); */
  transform: rotate(50deg);
  /* background: rgb(0,0,0);
background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(72,73,72,1) 0%, rgba(153,152,152,1) 100%);  */
  background: rgb(83, 83, 83);
  background: rgb(68 64 64);
}

.on-hover {
  background-color: rgb(92, 92, 92);
  border-radius: 10px;

}

.on-selected {
  background-color: rgb(60, 154, 255);
  border-radius: 10px;

}


/* lalageeek logo text */
.letterSpace {
  margin-left: 2px;
}

.reversed {
  display: inline-block;
  transform: scaleX(-1);
}

.dotCom {

  writing-mode: vertical-rl;
  transform: rotate(320deg);
}

.dot {
  display: inline-block;
  vertical-align: middle;
  /* Align the dot vertically with the text */
  margin-right: 12px;
  color: #97200f;
  color: #0f9779;
}
</style>
<!-- GLOBAL STYLES -->
<style>
.bg1 {

  background-color: #0f9747;
}

/* UNVERSAL HELPER CLASSES */
/* UNVERSAL HELPER CLASSES */
/* UNVERSAL HELPER CLASSES */
/* UNVERSAL HELPER CLASSES */
/* UNVERSAL HELPER CLASSES */
.dib,
.ilb {
  display: inline-block;
}

.noSel {
  user-select: none;
}

.curPoint {
  cursor: pointer;
}

.curNormal {
  cursor: normal;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.mw {
  max-width: 100%;
}

.mh {
  max-height: 100%;
}

.l0 {
  left: 0;
}

.r0 {
  right: 0;
}

.t0 {
  top: 0;
}

.b0 {
  bottom: 0;
}

.posRel {
  position: relative;

}

.z0 {
  z-index: 0
}

.z1 {

  z-index: 1
}

.z2 {

  z-index: 2
}

.z3 {

  z-index: 3
}

.posRel {
  position: relative;
}

.posAbs {
  position: absolute;
}

.posFix {
  position: fixed;
}

/* nicere scroll bars */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
}


.v-main {
  /* overflow-anchor: none; */
}

/* fuck vuetify (no frustration here, drink  your shitty coffee and move along*/
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}
</style>
