<template>
    <div>
        <p>This is a Home page!</p>
        <p>You have selected language => {{ lang }}</p>
        <p ><b style="color:orange">.. why did u come here?</b> Just get this over with and crash this stupid server..</p>
        <p>Click this button to END the universe...</p>
        
        <!-- <EmptyTemplate></EmptyTemplate> -->

        <BaseBtn :lazyColorType="2" :onClick="goToErrorView" :fontAwesomeOn="'fa-solid  fa-bomb fa-fw'"
                        :fontAwesomeOff="'fa-solid  fa-bomb fa-fw'" />
    </div>

</template>

<script>
// import puppeteer from 'puppeteer';

import BaseBtn from '@/components/Base/BaseBtn.vue';
import axios from '../plugins/AxiosSettings2.js'
import lalalogger from "@/plugins/lalalogger.js"

const componentName = "HomeView";
let getlog=lalalogger.init(componentName);



export default {

    //     mounted: function() {
    //     let elHtml = document.getElementsByTagName('html')[0]
    //     elHtml.style.overflowY = 'hidden'
    //   },
    //   destroyed: function() {
    //     let elHtml = document.getElementsByTagName('html')[0]
    //     elHtml.style.overflowY = null
    //   }

    // props: {
    //     componentName: {
    //       type: String,
    //       required: false,
    //       default:""
    //     }
    //   },
    props: {
        lang: {
            type: String, required: true,
            //   default: "asd"
        },
        tard: {
            type: Boolean, required: false,
              default: false,
        },
        tard2: {
            type: String, required: false,
              default: "",
        },
        fun: {
            type: Function, required: false,
              default: null,
        },
    },

    data: () => ({

    }),
    beforeCreate() {

        // const log = getlog("beforeCreate");
        // log("initLogger");
     
    },
    updated()
    {
        const log = getlog("updated");
        log();
    },
    mounted() {

        // hide scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = 'hidden';

        const log = getlog("mounted");

        log((elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));

        // fetch('https://www.youtube.com/@aztralenforcer')
        //     .then(response => response.text())
        //     .then(html => {
        //         console.log(html); // The HTML content of the website
        //     })
        //     .catch(error => {
        //         console.error('Error fetching website:', error);
        //     });

            // let omg=log.getlog("test2");

            // omg("test2");
        // this.fetchData();
    },

    methods: {
        goToErrorView()
        {
            this.$router.push({ path: "/ErrorView", query: { lang: this.lang } })
        },
        fetchData() {   


            // fetch('http://localhost:3123/api/test')
            fetch('https://lalageek.com:3123/api/test')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Response:', data);
                    // Handle the response data
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });


            // axios.get('http://localhost:3123/api/test')
            //     .then(response => {
            //         console.log(response);
            //         // Handle the response data
            //     })
            //     .catch(error => {
            //         console.error('Error fetching data:', error);
            //     });
        },
        async triggerPuppeteer() {
            try {
                const response = await fetch('https://www.w3schools.com');
                const data = await response.json();
                console.log(data); // Handle response from Puppeteer action
            } catch (error) {
                console.error('Error triggering Puppeteer:', error);
            }
        }
        // async scrapeYouTube() {
        //   try {
        //     // Read the browser's user agent string
        //     const userAgentString = navigator.userAgent;


        //     const options = {
        //       headers: {
        //         'User-Agent': userAgentString
        //       }
        //     };

        //     // Make the Axios request
        //     const response = await axios.get('https://www.youtube.com/', options);

        //     // Parse the HTML response and extract information
        //     console.log(response.data); // This will print the HTML content of the page
        //   } catch (error) {
        //     console.error('Error:', error);
        //   }
        // }
    }
}
</script>
