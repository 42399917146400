<template>


    <div class="h100 mh100 ma-0 pa-0" @click="fadeInImage">
        <!--  @click.once="endsThis" -->

        <div class="overlay fade-in" v-if="showOverlay" >
            <div class="overlayBg"></div>
             <img src="@/assets/joebiden.jpg" alt="Joe Biden" class="fade-in" @click.stop>
        </div>


        <div ref="test" id="bouncing-element" :style="{ backgroundColor: getColorNormal(this.currentIndex) }">
            🥶😩!FAIL!🤬👾
            <div style="font-size:18px;text-align: center;">Click Joe Biden</div>
            <div style="font-size:18px;text-align: center">to continue..👶</div>
        </div>

        <v-container fluid class="h100 mh100 ma-0 pa-0">
            <v-row class="h100 mh100 ma-0 pa-0">
                <v-col cols="2" class="h100 mh100 ma-0 pa-0">

                    <div style=" display: flex;  flex-direction: column;  height:100%;">
                        <span v-for="(letter, index) in gameoverToChar" :key="index" class="content-element"
                            :style="{ color: getColor(index) }">
                            {{ letter }}
                        </span>
                    </div>

                </v-col>


                <v-col cols="8" class="ma-0 pa-0 posRel" style="align-content: center;display: grid;">


                    <div class="w100" style="text-align: center;">
                        <img src="@/assets/unsatisfied-sheep.png" alt="Unsatisfied Sheep" class="w100"
                            style="min-width: 480px">
                    </div>

                </v-col>
                <v-col cols="2" ma-0 pa-0>
                    <div style=" display: flex;  flex-direction: column;  height:100%;">
                        <span v-for="(letter, index) in gameoverToChar" :key="index" class="content-element"
                            :style="{ color: getColorRev(index) }">
                            {{ letter }}
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-container>


    </div>


</template>

<script>

import lalalogger from "@/plugins/lalalogger.js"
let getlog = lalalogger.init("ErrorView");


export default {
    props: {
        lang: {
            type: String,
            required: true,

        },
    },

    data() {
        return {
            gameover: "GAMEOVER",
            showOverlay: false,


            // colors: ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'], // Rainbow colors
            colors: ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3', '#8B00FF'],

            currentIndex: 0,
            intervalId: null,

            x: 0,
            y: 0,
            dx: 4,
            dy: 4,
            element: null,
            appBarHeight: 64,

            stopBounce: false,

            endInterval: null,

        };
    },
    computed: {
        gameoverToChar() {
            return this.gameover.split('');
        }
    },

    beforeMount() {
        const log = getlog("beforeMount");

        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = 'hidden';


        log((elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));


        this.intervalId = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.colors.length;
        }, 100); // Change color every 200 milliseconds



    },
    mounted() {
        const log = getlog("mounted");


        log("test", this.$refs.test);

        this.element = this.$refs.test;

        this.updatePosition();





    },
    beforeDestroy() {
        clearInterval(this.intervalId);
        clearInterval(this.endInterval);
    },
    methods: {
        fadeInImage() {
            this.showOverlay = true;

            this.endsThis();
        },
        endsThis() {
            const log = getlog("endsThis");


            log("endsThis");
            this.stopBounce = true;



            this.endInterval = setInterval(() => {
                this.$router.push({ path: "/", query: { lang: this.lang } })
            }, 1000);
        },
        getColor(index) {
            let i = this.currentIndex + index;

            if (i >= this.colors.length)
                i = 0;

            // console.log(i);

            return this.colors[i];
        },
        getColorNormal() {
            let i = this.currentIndex;

            if (i >= this.colors.length)
                i = 0;

            // console.log(i);

            return this.colors[i];
        },

        getColorRev(index) {

            let i = (this.currentIndex + index);

            if (i >= this.colors.length)
                i = 0;

            // console.log(this.colors.length);


            return this.colors[(this.colors.length - 1) - i];
        },
        getColor2() {

            return this.colors[Math.floor(Math.random() * this.currentIndex)];
        },
        updatePosition1() {
            this.x += this.dx;
            this.y += this.dy;

            const maxX = window.innerWidth - this.element.offsetWidth;
            const maxY = window.innerHeight - this.element.offsetHeight;

            if (this.x < 0 || this.x > maxX) {
                this.dx = -this.dx; // Reverse horizontal velocity when reaching the left or right edge
            }
            if (this.y < 0 || this.y > maxY) {
                this.dy = -this.dy; // Reverse vertical velocity when reaching the top or bottom edge
            }

            this.element.style.left = this.x + 'px';
            this.element.style.top = this.y + 'px';


        },
        updatePosition() {
            this.x += this.dx;
            this.y += this.dy;

            const maxX = document.documentElement.clientWidth - this.element.offsetWidth;
            const maxY = document.documentElement.clientHeight - this.element.offsetHeight - this.appBarHeight;

            if (this.x < 0 || this.x > maxX) {
                this.dx = -this.dx; // Reverse horizontal velocity when reaching the left or right edge
            }
            if (this.y < 0 || this.y > maxY) {
                this.dy = -this.dy; // Reverse vertical velocity when reaching the top or bottom edge
            }

            this.element.style.left = this.x + 'px';
            this.element.style.top = this.y + 'px';


            if (this.stopBounce === false) {

                requestAnimationFrame(this.updatePosition);

            }


        },
        updatePosition2() {
            this.x += this.dx;
            this.y += this.dy;

            const maxX = window.innerWidth - this.element.offsetWidth;
            const maxY = window.innerHeight - this.element.offsetHeight;

            if (this.x < 0 || this.x > maxX) {
                this.dx = -this.dx; // Reverse horizontal velocity when reaching the left or right edge
            }
            if (this.y < 0 || this.y > maxY) {
                this.dy = -this.dy; // Reverse vertical velocity when reaching the top or bottom edge
            }

            this.element.style.left = this.x + 'px';
            this.element.style.top = this.y + 'px';

            requestAnimationFrame(this.updatePosition);
        },

    }


};
</script>
<!-- Scoped Styles -->
<style scoped>
.overlayBg
{
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background-color: black;
    
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* make sure it overlays everything */
}

.fade-in {
    animation: fadeIn 1000ms ease-in-out forwards;
    /* fade-in animation */
    max-width: 100%;
    max-height: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}




.content-element {
    flex-grow: 1;
    /* Allow the content element to grow and take up remaining space */
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
    /* Vertically center the content */
    font-size: 2em
}

#bouncing-element {
    font-size: 24px;
    width: 200px;
    height: 100px;
    background-color: red;
    position: absolute;
    z-index: 1;
}
</style>
