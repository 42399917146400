<template>
    <div>
        <!-- <div v-for="(videoId, index) in videos" :key="index">
            <RumbleVideo :videoId="videoId" />
        </div> -->
        paradisefeed



        <!-- 
        <iframe width="560" height="315" src="https://www.youtube.com/@paradisefeed" frameborder="0"
            allowfullscreen></iframe> -->


    </div>
</template>
<script>
// import RumbleVideo from '@/components/RumbleVideo.vue';
import lalalogger from "@/plugins/lalalogger.js"

const componentName = "aZtraLEnForceR";
let getlog = lalalogger.init("ParadiseFeed");

export default {
    components: {
        // RumbleVideo
    },
    data: () => ({
        apiKey: "AIzaSyApvAQQnRKMG-yNlPzGBnpPNlm6pCYHxtI", //paradisefeed
        uploadId: 'UCi6T8naQeP_GTTzawxhm80Q', //PF
        // videos: [
        //     "v3r3v1p/?pub=3bbgfz",
        // ],

    }),
    mounted() {

        let log = getlog("mounted");
        // hide scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = null;


        log((elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));

    },


};
</script>
