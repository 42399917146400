
<template>
    <div>

        <p>I love music. And no not just stereotype music. I can name more genres that i love then i hate. Really! I dont
            think there is any type of music that i dont like. It all really depends on the artist and the message! Pop,
            rap, jazz, dance, rock, trance, classic, country i seriouslu love it all! But its no secret that I had my peak
            music experience in the late 90'es and was priviliged to experience


            For a good 30 years i hated rap music.. but then therapy eminem tom mac donald.

            WHen i was young, it was the other way around. I respect all genres of music and understands why they matters.
            However being born in in 1986 i had the joy of experincing the climaks of electronic dance music. In particular
            during the late 90'ies. The peak moment when Infected Mushroom, DNA & Astral Projection (the name aZtraL
            EnForceR was inspired partly by these legends. I surrender to => Aztral Projection - Nilaya) changed the course
            of psychedelic trance music, and for a century almost became mainstream.

            While the world falls into complete chaos. a peaceful dance movement has emerged from the nation of izrael.

            (I dont not take a 1:1 side in the Izrael - Palestine debat. It's too complicated)

            Before this genre of music peaked though, we had a mix of pop,dance,trance,house and disco music era. You
            remember Aqua,toybox, Tiggy, Aquagen etc... </p>

            <div
            style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/aztralenforcer" title="aZtraL EnForceR" target="_blank"
                style="color: #cccccc; text-decoration: none;">aZtraL EnForceR</a> · <a
                href="https://soundcloud.com/aztralenforcer/lick-on-dem-shjouus-wtf-lume-1-outtro"
                title="Lick-on-dem shjouus, wtf-lume. 1 (Outtro)" target="_blank"
                style="color: #cccccc; text-decoration: none;">Lick-on-dem shjouus, wtf-lume. 1 (Outtro)</a>
        </div>

        <iframe width="100%" height="300" scrolling="no" frameborder="no" :allow="autoplay ? 'autoplay' : ''"
            :src="getSoundCloudUrl('1267849699')">
        </iframe>




        <div
            style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/aztralenforcer" title="aZtraL EnForceR" target="_blank"
                style="color: #cccccc; text-decoration: none;">aZtraL EnForceR</a> · <a
                href="https://soundcloud.com/aztralenforcer/liquor-talk-buffalo-shoes-vol-1"
                title="Liquor Talk &amp; Buffalo Shoes Vol. 1" target="_blank"
                style="color: #cccccc; text-decoration: none;">Liquor Talk &amp; Buffalo Shoes Vol. 1</a>
        </div>

        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"

        :src="getSoundCloudUrl('1222649512')">


        </iframe>



    </div>
</template>
<script>


export default {

    data: () => ({
        name:"MusicView",
        autoplay: false,
        loop: true,
        videos: [
            "ZH6tTBRB9g4",
            "Zjummn3ap6Y",
            "WJMXmiBZnPk",
            "dnGkH2ypFLE"
        ],
    }),
    mounted()
    {
       // Show scrollbar
      let elHtml = document.getElementsByTagName('html')[0];
      elHtml.style.overflowY = null;

      console.log(this.name,"mounted => "+ (elHtml.style.overflowY === "hidden" ? "Hide Scrollbar":"Show Scrollbar" ) );
    },
    methods: {
        getSoundCloudUrl(trackId) {




            // baseURL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + trackId + "&color=%2300d0ff&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true";

            const baseURL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/";
            const autoplayParam = this.autoplay ? "&auto_play=true" : "&auto_play=false";

            return (baseURL + trackId + "&color=%2300d0ff" + autoplayParam + "&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true");

        }



    }



}
</script>